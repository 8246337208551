.page-research{
    &--list{
        @include for-desktop-up{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            .card{
                width: calc((100% - 20px)/2);
                &:nth-child(2n){
                    margin-left: 20px;
                }
            }
        }
    }
    &--global-ctas{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include for-desktop-up{
            .card-cta-global{
                width: calc((100% - 20px)/2);
                &:nth-child(2n){
                    margin-left: 20px;
                }
            }
        }
    }
}