.page-feeds{
    h2{
        display: flex;
        justify-content: space-between;
        .btn{
            font-size: 1rem;
            font-weight: 500;
            svg{
                width: 13px;
            }
        }
    }
    &--tabs{
        margin-bottom: 60px;
        @include for-tablet-only{
            margin-top: 25px;
        }
    }
    &--featured-card{
        &:first-of-type{
            @include for-tablet-only{
                padding-top: 35px;
            }
        }
    }
    .carousel--content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include for-phone-up{
            .card{
                width: calc((100% - 20px)/2);
                &:nth-child(2n){
                    margin-left: 20px;
                }
            }
        }
        @include for-desktop-up{
            .card{
                width: calc((100% - 40px)/3);
                &:nth-child(2n){
                    margin-left: 0;
                }
                &:nth-child(3n -1){
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
    &--events{
        &-carousel{
            
            .carousel--dots-item{
                &.active{
                    background-color: $purple;
                }
            }
        }
    }
    &--learning-modules{
        &-grid{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include for-desktop-up{
                .card-feed{
                    width: calc((100% - 20px)/2);
                    &:nth-child(2n){
                        margin-left: 20px;
                    }
                }
            }
            
        }
    }

    &--global-ctas{
        padding-top: 70px;
        padding-bottom: 70px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &:last-of-type{
            padding-bottom: 70px;
        }
        @include for-desktop-up{
            .card-cta-global{
                width: calc((100% - 20px)/2);
                &:nth-child(2n){
                    margin-left: 20px;
                }
            }
        }
    }

    &--clinical-library{
        &-carousel{
            .carousel--dots-item{
                &.active{
                    background-color: $yellow;
                }
            }
        }
    }

    &--news{
        &-carousel{
            .carousel--dots-item{
                &.active{
                    background-color: $yellow;
                }
            }
        }
    }
}