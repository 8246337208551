.side-menu{
    display: flex;
    flex-direction: column;
    @media (min-height: 682px) {
        position: sticky;
        top: 10px;
    }
    @include for-tablet-landscape-up{
        margin-left: -40px;
    }
    &--item{
        display: flex;
        background-color: $grey-light;
        border-radius: 100px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        padding: 20px 40px;
        text-decoration: none;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
        color: $blue;
        svg{
            fill: $blue;
            width: 10px;
            height: auto;
        }
        &[href]{
            &:hover, &.active{
                background-color: $blue;
                color: $white;
                svg{
                    fill: $yellow;
                }
            }
        }
        
    }
    .side-submenu{
        display: flex;
        flex-direction: column;
        padding-bottom: 6px;
        padding-top: 4px;
    }
    &--subitem{
        font-size: 16px;
        line-height: 1;
        color: $black;
        text-decoration: none;
        padding: 5px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 100px;
        font-weight: 500;
        margin-bottom: 4px;
        svg{
            fill: $blue;
            width: 8px;
            height: auto;
            visibility: hidden;
        }
        &:hover, &.active{
            color: $blue;
        }
    }
}