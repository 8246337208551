.page-start{
    overflow: hidden;
    background: $blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include for-tablet-landscape-up{
        flex-direction: row;
        align-items: inherit;
        justify-content: inherit;
    }
    &--content{
        width: 100%;
        @include for-tablet-landscape-up{
            max-width: 700px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            overflow: hidden;
        }
    }
    &--bg{
        @include for-tablet-landscape-up{
            width: calc(100vw - 700px);
            &::after{
                content: none;
            }
        }
    }
    &--logo{
        margin-bottom: 42px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: auto;
            height: 32px;
            fill: $white;
            @include for-tablet-portrait-up{
                height: 47px;
            }
        }
    }
    &--details{
        height: 100%;
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        .btn{
            margin-bottom: 10px;
        }
    }
    &--text{
        color: $white;
        text-align: center;
        margin-top: 12px;
        .btn{
            font-size: inherit;
            font-weight: 400;
            margin: 0 auto;
        }
    }
    &--top{
        position: absolute;
        top: -21vh;
        left: -48vh;
        height: 60vh;
        width: 60vh;
        border: 1px solid #E2E9EF;
        border-radius: 50%;
        figure{
            position: absolute;
            border-radius: 50%;
            overflow: hidden;
            &:nth-child(1){
                width: 13%;
                right: -6%;
                top: 40%
            }
            &:nth-child(2){
                width: 8%;
                right: 10%;
                top: 80%
            }
            &::after{
                padding-bottom: 100%;
            }
        }
    }
    &--bottom{
        position: absolute;
        bottom: -19vh;
        right: -30vh;
        height: 48vh;
        width: 48vh;
        border: 1px solid #E2E9EF;
        border-radius: 50%;
        figure{
            position: absolute;
            border-radius: 50%;
            overflow: hidden;
            &:nth-child(1){
                width: 17%;
                left: 12%;
                top: 2%;
            }
            &:nth-child(2){
                width: 8%;
                left: -4%;
                top: 44%;            
            }
            &::after{
                padding-bottom: 100%;
            }
        }
    }
}