.card-doctor-circular{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: $black;
    width: 130px;
    &--figure{ 
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
        background-color: $white;
        img{
            border-radius: 50%;
            object-fit: cover;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
        &::after{
           padding-bottom: 100%;
        } 
    }
    &--title{
        margin-top: 10px;
        font-size: 1.16rem;
        line-height: 1;
        text-align: center;
        font-weight: 600;
    }
}