.page-doctor-single{
    &--page-head{
        padding-top: 0;
    }
    &--page-content{
        .page-content--inner{
            @include for-desktop-up{
                display: flex;
            }
        }
    }
    &--aside{
        @include for-desktop-up{
            width: 40%;
            padding-right: 30px;
        }
    }
    &--info{
        position: relative;
        padding-bottom: 45px;
        margin-bottom: 25px;
        @include for-tablet-portrait-up{
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
        @include for-desktop-up{
            padding-bottom: 0;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            position: sticky;
            top: 15px
        }
    }
    &--figure{
        border-radius: 24px;
        overflow: hidden;
        &::after{
            padding-bottom: 100%;
        }
    }
    &--title{
        // padding: 25px 0;
        // @include for-tablet-only{
            width: 90%;
            left: 5%;
            box-shadow: 0px 0px 10px 3px rgba($black, 0.26);
            position: absolute;
            bottom: 0;
            background-color: $white;
            border-radius: 24px;
            padding: 25px 15px;
        // }
        @include for-desktop-up{
            position: relative;
            width: 100%;
            box-shadow: none;
            padding-left: 0;
            padding-bottom: 0;
            padding-right: 0;
            left: 0;
        }
    }
    &--name{
        margin-bottom: 5px;
    }
    &--postnominals{
        color: $grey_subtitle;
        margin-bottom: 10px;
    }
    &--tags{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .text--tag{
            font-size: 1rem;
            margin-bottom: 5px;
        }
    }
    &--favourite{
        $self: &;
        position: absolute;
        right: 15px;
        bottom: 35px;
        height: 25px;
        @include for-desktop-up{
            bottom: 5px;
            right: 0;
        }
        svg{
            height: 25px;
            width: auto;
            fill: #000000;
            cursor: pointer;
            transform-origin: center center;
            &.icon-solid{
                fill: $blue;
            }
            
        }
        .loading{
            height: 25px;
            &--spinner{
                width: 25px;
                height: 25px;
                border: 0.2rem solid #f3f3f3;
                border-top: 0.2rem solid #1f1d1da1;
            }
        }
        &-tooltip{
            position: absolute;
            bottom: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            background-color: #000000;
            color: $white;
            white-space: nowrap;
            font-size: 0.8rem;
            font-weight: 700;
            line-height: 1;
            padding: 10px 15px;
            border-radius: 6px;
            visibility: hidden;
            &:after{
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: black transparent transparent transparent;
            }
            &.solid{
                background-color: $blue;
                &:after{
                    border-color: $blue transparent transparent transparent;
                }
            }
        }
        &:hover{
            #{ $self }-tooltip{
                visibility: visible;
            }
        }
    }
    &--content{
        @include for-desktop-up{
            flex: 1;
            padding-left: 30px;
        }
    }
    &--tabs{
        padding-top: 20px;
        &-content{
            padding-top: 20px;
            .btn{
                margin-bottom: 6px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
    &--medical-object{
        box-shadow: 0px 0px 10px 3px rgba($black, 0.26);
        border-radius: 34px;
        padding: 40px 35px;
        padding-bottom: 30px;
        text-align: center;
        margin-top: 40px;
        h2{
            margin-bottom: 20px;
        }
        p{
            font-size: 1.33rem;
            padding-bottom: 30px;
        }            
    }
    &--accordions{
        .accordion--content {
            &:not(.collapsed){
                padding-top: 35px;
                padding-bottom: 35px;
            }
        }
    }
    &--specialintrests{
        &-intro{
            font-size: 1.33rem;
            font-weight: 600;
            line-height: 1.8rem;
            margin-bottom: 15px;
        }
        &-tags{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            &-item{
                font-size: 1.33rem;
                font-weight: 600;
                line-height: 1;
                border-radius: 100px;
                border: 1px solid $black;
                padding: 7px 20px;
                margin-bottom: 7px;
                margin-right: 7px;
                &:last-of-type{
                    margin-right: 0;
                }
            }
        }
    }
    &--publications{
        &-item{
            &:not(:last-child){
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid $grey_border;
            }
        }
    }
    &--bio{
        h3{
            margin-bottom: 10px;
        }
    }
    &--ea{
        .card{
            &:first-of-type{
                margin-top: 0;
            }
        }
    }
    &--contact{
        &-details{
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;
            margin-right: -10px;
            justify-content: space-between;
            &__item{
                display: flex;
                font-size: 1.6rem;
                line-height: 1;
                font-weight: 700;
                align-items: center;
                cursor: pointer;
                margin-right: 10px;
                margin-bottom: 10px;
                min-width: calc(50% - 10px);
                @include for-tablet-portrait-up{
                    &:hover{
                        color: $blue;
                        span{
                            background-color: $black;
                        }
                    }
                }
                span{
                    display: flex;
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    background-color: $blue;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;
                    transition: all 0.2s ease;
                    svg{
                        width: 30%;
                        height: auto;
                        fill: $white;
                    }
                }
            }
        }
    }
}