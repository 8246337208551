.panel{
    background-color: $grey-light;
    border: 1px solid $grey;
    padding: 25px;
    border-radius: 10px;
}
.panel--header{
    padding: 35px;
    background-color: $blue;
    border-radius: 10px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.23;
    svg{
        width: 25px;
        height: auto;
        fill: $yellow;
    }
}