.text{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    margin-right: 2px;
    font-weight: 500;
    &:last-child{
        margin-right: 0;
    }
    svg{
        margin-right: 7px;
        height: 13px;
        width: auto;
        fill: $black;
        flex: 0 0 auto;
    }
    figure{
        width: 20px;
        margin-right: 7px;
    }
    &--category{
        border-radius: 5px;
        font-size: 0.83rem;
        font-weight: 600;
        padding: 3px 10px;
    }
    &--tag{
        border-radius: 100px;
        font-size: 0.83rem;
        font-weight: 500;
        padding: 3px 10px;
        color: $dark_40;
        background-color: rgba($color: $black, $alpha: 0.1);
        svg{
            fill: $dark_40
        }
        &.dark{
            color: $white;
            background-color: $grey_subtitle;
            svg{
                fill: $white
            }
        }
    }
    &--author{
        font-size: 0.91rem;
        line-height: 1.46rem;
        font-weight: 500;
        color: $dark_40;;
        figure{
            border-radius: 50%;
            overflow: hidden;
            &::after{
                padding-bottom: 100%;
            }
        }
    }
    &.blue{
        background-color: $blue;
        color: $white;
        svg{
            fill: $white;
        }
    }
    &.yellow{
        background-color: $yellow;
        color: $black;
        svg{
            fill: $black;
        }
    }
    &.purple{
        background-color: $purple;
        color: $white;
        svg{
            fill: $white;
        }
    }
    &.green{
        background-color: $green;
        color: $white;
        svg{
            fill: $white;
        }
    }
    &.red{
        background-color: $red_error;
        color: $white;
        svg{
            fill: $white;
        }
    }
    &.white{
        background-color: $white;
        color: $black;
        svg{
            fill: $black;
        }
    }
    &.grey{
        background-color: $grey;
        color: $black;
        svg{
            fill: $black;
        }
    }
    &.text-green{
        color: $green;
        svg{
            fill: $green;
        }
    }
    &.bold{
        font-weight: 700;
    }
}