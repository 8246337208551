.page-clinical{
    h2{
        display: flex;
        justify-content: space-between;
        .btn{
            font-size: 1rem;
            font-weight: 500;
            svg{
                width: 13px;
            }
        }
    }
    &--ctas{
        display: flex;
        flex-direction: column;
        margin-top: -6px;
        .card-cta-subpage{
            margin: 0;
            margin-bottom: 6px;
        }
        @include for-tablet-landscape-up{
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -15px;
            .card-cta-subpage{
                width: calc((100% - 15px)/2);
                &:nth-child(2n){
                    margin-left: 15px;
                }
                margin-bottom: 15px;
            }
        }
    }
    .carousel--content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include for-phone-up{
            .card{
                width: calc((100% - 20px)/2);
                &:nth-child(2n){
                    margin-left: 20px;
                }
            }
        }
        @include for-desktop-up{
            .card{
                width: calc((100% - 40px)/3);
                &:nth-child(2n){
                    margin-left: 0;
                }
                &:nth-child(3n -1){
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
    &--tp, &--tt{
        &-carousel{
            .carousel--dots-item{
                &.active{
                    background-color: $yellow;
                }
            }
        }
    }
}