.video{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    background-color: $grey_light;
    &.hidden{
        > div{
            visibility: hidden;
            opacity: 0;
        }
    }
    > div{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s ease;
    }
    iframe{
        border:0
    }
    .loading{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}