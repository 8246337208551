.page-register-foxo{
    &--head{
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        @include for-tablet-portrait-up{
            padding-top: 35px;
        }
    }
    &--content{
        padding-top: 35px;
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 35px;
        }
    }
    &--details{
        display: flex;
        flex-direction: column;
        align-items: center;
        svg{
            width: 80px;
            margin-bottom: 15px;
        }
        h3{
            margin-bottom: 10px;
        }
        figure{
            width: 230px;
            margin-bottom: 15px;
            &::after{
                padding-bottom: 25%;
            }
        }
        &-text{
            text-align: center;
            padding-bottom: 40px;
            a{
                text-decoration: none;
                font-weight: 500;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .btn-outline{
            margin-top: 10px;
        }
    }
}