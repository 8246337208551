.card-doctor{
    overflow: hidden;
    border-radius: 10px;
    background-color: $grey_light;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding-top: 20px;
    cursor: pointer;
    text-decoration: none;
    color: $black;
    &--content{
        display: flex;
        flex-direction: column;
        padding: 24px 18px;
        align-items: center;
        flex: 1
    }
    &--figure{ 
        width: 40%;
        border-radius: 12px;
        overflow: hidden;
        img{
            object-fit: cover;
        }
        &::after{
           padding-bottom: 100%;
        } 
    }
    &--title{
        margin-bottom: 10px;
        font-size: 1.833rem;
        line-height: 2rem;
        text-align: center;
    }
    &--subtitle{
        margin-bottom: 10px;
        text-align: center;
    }
    &--tags{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: -5px;
        .text--tag{
            margin-bottom: 5px;
        }
    }
    &--favourite{
        position: absolute;
        right: 20px;
        top: 20px;
        svg{
            height: 15px;
            width: auto;
            fill: $blue;
        }
    }
}