// Image
.flexible-image{
    position: relative;
    z-index: 2;
    width: 100%;
    padding-bottom: var(--section-space);
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
   img{
        max-width: var(--max-content-width);
        width: 100%;
        display: block;
        height: auto;
        margin: 0 auto;
        box-shadow: 2px 5px 15px 0px rgba(0,0,0,0.1);
   }
   &.has-offset{
        img{
            margin: -50px auto 0 auto;
            @media screen and (min-width: 960px) {
                margin: -150px auto 0 auto;
            }
        }
   }
}


.flexible-video{
    position: relative;
    z-index: 2;
    width: 100%;
    padding-bottom: var(--section-space);
    // padding-left: 15px;
    // padding-right: 15px;
    // @media screen and (min-width: 960px) {
    //     padding-left: 20px;
    //     padding-right: 20px;
    // }
   > div{
        max-width: var(--max-content-width);
        width: 100%;
        display: block;
        height: auto;
        margin: 0 auto;
        video{
            box-shadow: 2px 5px 15px 0px rgba(0,0,0,0.1);
        }
   }
   &.has-offset{
        > div{
            margin: -50px auto 0 auto;
            @media screen and (min-width: 960px) {
                margin: -150px auto 0 auto;
            }
        }
   }
}

// List
.flexible-list{
    position: relative;
    z-index: 2;
    width: 100%;
    padding-bottom: var(--section-space);
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.flexible-list--inner{
    max-width: var(--max-content-width);
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    --gap: 40px;
    gap: var(--gap);
    @media screen and (min-width: 960px) {
        flex-flow: row nowrap;
        --gap: 80px;
        gap: var(--gap);
    }
}

.flexible-list--text{
    @media screen and (min-width: 960px) {
        flex: 0 0 auto;
        width: calc(50% - var(--gap));
    }
}

.flexible-list--items{
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    @media screen and (min-width: 960px) {
        flex: 0 0 auto;
        width: calc(50% - (var(--gap) / 2));
    }
}

.flexible-list--item{
    
    border-radius: 15px;
    padding: 25px;
    display: flex;
    flex-flow: row nowrap;
    gap: 25px;
    align-items: center;
    background-color: $white;
    &.is-bg-grey{
        background-color: $grey_light;
    }
    &.is-type-small{
        padding: 20px;
        gap: 20px;
    }
    &.is-type-extra-small{
        padding: 9px;
        gap: 13px;
    }
}

.flexible-list--item-icon{
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $grey;
    
    @media screen and (min-width: 560px) {
        width: 90px;
        height: 90px;
    }
    svg{
        fill: currentColor;
        display: block;
        width: 30px;
        height: auto;
    }
    .is-bg-grey &{
        box-shadow: 5px 5px 30px 0px rgba(0,0,0,0.1);
        background-color: $white;
        border: none;
    }
    .is-type-small &{
        width: 50px;
        height: 50px;
        svg{
            width: 24px;
        }
        @media screen and (min-width: 560px) {
            width: 70px;
            height: 70px;
        }
    }
    .is-type-extra-small &{
        width: 46px;
        height: 46px;
        svg{
            width: 18px;
        }
    }
}

.flexible-list--item-text{
    h3{
        font-size: clamp(20px, 4vw, 26px);
        color: $blue;
        margin-bottom: 0.4em;
        .is-type-small &{
            font-size: clamp(18px, 3vw, 22px);
        }
        .is-type-extra-small &{
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    p{
        margin-bottom: 0 !important;
    }
}

// Accordion
.flexible-accordion{
    padding-bottom: var(--section-space);
    padding-left: 15px;
    padding-right: 15px;
}

.flexible-accordion--inner{
    max-width: 730px;
    margin: 0 auto;
}

.flexible-accordion--title{
    text-align: center;
}

.flexible-accordion--items{
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    @media screen and (min-width: 560px) {
        gap: 15px;
    }
}

.flexible-accordion--item {
    background-color: $grey_light;
    border-radius: 15px;
}

.flexible-accordion--item-title {
    cursor: pointer;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    gap: 10px;
    @media screen and (min-width: 560px) {
        padding: 40px 30px;
        font-size: 24px;
    }
    .icon{
        position: relative;
        width: 15px;
        height: 15px;
        @media screen and (min-width: 560px) {
            width: 20px;
            height: 20px;
        }
        &::before{
            content: "";
            width: 100%;
            height: 2px;
            background-color: $blue;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &::after{
            content: "";
            width: 100%;
            height: 2px;
            background-color: $blue;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }
    &.open{
        .icon{
            &::after{
                display: none;
            }
        }
    }
}

.flexible-accordion--item-content {
    overflow: hidden;
    height: auto;
    max-height: 99999px;
    padding: 0px 15px 15px 45px;
    @media screen and (min-width: 560px) {
        padding: 0px 25px 25px 60px;
    }
    &.collapsed {
        max-height: 0;
        padding-bottom: 0;
        padding-top: 0;
    }
}
  
// CTA
.flexible-cta{
    position: relative;
    z-index: 2;
    width: 100%;
    padding-bottom: var(--section-space);
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.flexible-cta--inner{
    max-width: var(--max-content-width);
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    --gap: 40px;
    gap: var(--gap);
    @media screen and (min-width: 960px) {
        flex-flow: row nowrap;
        align-items: center;
        --gap: 80px;
        gap: var(--gap);
    }
}

.flexible-cta--text{
    @media screen and (min-width: 960px) {
        flex: 0 0 auto;
        width: calc(50% - var(--gap));
    }
    h2{
        .flexible-cta &{
            font-size: clamp(30px, 5vw, 68px);
            line-height: 1;
            margin-bottom: 0.5em;
        }
    }
}

.flexible-list--image{
    @media screen and (min-width: 960px) {
        flex: 0 0 auto;
        width: calc(50% - (var(--gap) / 2));
    }
}

.flexible-cta--text-list{
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
    padding-bottom: 3rem;
}

.flexible-cta--text-list-item{
    font-size: 20px;
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
    align-items: center;
    svg{
        width: 26px;
        height: 26px;
        fill: #04AC3D;
    }
}

// Carousel
.flexible-carousel{
    padding-bottom: var(--section-space);
}

.flexible-carousel--title{
    text-align: center;
}

.flexible-carousel--item{
    padding: 20px;
    // min-width: 600px;
}

.flexible-carousel--item-inner{
    background-color: $white;
    box-shadow: 2px 5px 25px 0px rgba(0,0,0,0.1);
    padding: 40px;
    border-radius: 15px;
}

.flexible-carousel--item-text{
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
}

.flexible-carousel--item-author{
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
    align-items: center;
    img{
        flex: 0 0 auto;
        display: block;
        width: 50px;
        height: auto;
    }
}

.flexible-carousel--item-author-name{
    font-size: 12px;
    line-height: 1;
    padding-bottom: 6px;
}

.flexible-carousel--item-author-position{
    font-size: 12px;
    line-height: 1;
    color: $grey_subtitle;
}

.flexible-carousel--dots{
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
}

