@import 'layout/header';
@import 'layout/side-menu';
@import 'layout/bottom-menu';
@import 'layout/footer';
@import 'layout/toasts';

.main{
	min-height: 100vh;
	padding-top: 0;
	z-index: 1;
	position: relative;
	@include for-tablet-only{
		padding-bottom: $bottom_menu_height + 20;
	}
	&.full{
		min-height: 100vh;
		padding-top: 0;
	}
	&.with-header-fixed{
		min-height: calc(100vh - #{$header_height});
		padding-top: $header_height;
	}
	&.no-bottom-menu{
		padding-bottom: 0;
	}
	@include for-tablet-portrait-up{
		// &:not(.page-start){
		// 	&:not(.page-login){
		// 		&:not(.page-register){
		// 			&:not(.page-register-success){
		// 				background: linear-gradient(to right, #FAFAFA 0%,#FAFAFA 50%, $white 50%, $white 50%, $white 100%);
		// 			}
		// 		}
		// 	}
		// }
		&:not(.no-bottom-menu){
			background: linear-gradient(to right, #FAFAFA 0%,#FAFAFA 50%, $white 50%, $white 50%, $white 100%);
		}
	}
	&--inner{
		@include for-tablet-only{
			display: none;
		}
		@include inner-div;
		display: flex;
		min-height: 100vh;
	}
	&--sidebar{
		@include for-tablet-only{
			display: none;
		}
		width: 35%;
		padding-top: 45px;
		padding-right: $padding_default_m;
		padding-bottom: 45px;
		max-width: 300px;
		@include for-tablet-landscape-up{
			width: 30%;
		}
		@include for-desktop-up{
			width: 25%;
		}
	}
	&--content{
		background-color: $white;
		width: 65%;
		padding-top: 45px;
		padding-left: $padding_default_m;
		padding-bottom: 45px;
		display: flex;
		flex-direction: column;
		flex: 1;
		@include for-tablet-landscape-up{
			width: 70%;
			padding-left: $padding_default_d;
		}
		@include for-desktop-up{
			width: 75%;
		}
		
	}
}

.page-content{
    &--inner{
        @include inner-div;
		@include for-tablet-portrait-up{
			padding: 0;
		}
    }
}