.page-events{
    &--grid{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include for-phone-up{
            .card-featured{
                width: calc((100% - 20px)/2);
                &:nth-child(2n){
                    margin-left: 20px;
                }
            }
        }
        @include for-desktop-up{
            .card-featured{
                width: calc((100% - 40px)/3);
                &:nth-child(2n){
                    margin-left: 0;
                }
                &:nth-child(3n -1){
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
        &-message{
            padding-top: 30px;
            font-weight: bold;
        }
    }
}