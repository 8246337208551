.modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    &--backdrop{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color: $black, $alpha: 0.5);
    }
    &--box{
        background-color: $white;
        border-radius: 34px;
        padding: 60px 40px 60px 40px;
        z-index: 1;
        max-height: 95%;
        overflow-y: auto;
        position: relative;
        max-width: 700px;
        h3{
            margin-bottom: 20px;
        }
        @include for-phone-only{
            width: 95%;
        }
    }
    &--close{
        width: 30px;
        height: 30px;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top:15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 45%;
            fill: #8e8e93;
        }
        &:hover{
            background-color: $grey;
            svg{
                fill: $grey_dark;
            }
        }
    }
    &--content{
        $self: &;
        &-with-btns{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $black;
            max-width: 320px;
            @include for-tablet-portrait-up{
                width: 320px;
            }
            .btn{
                &:not(:last-child){
                    margin-bottom: 10px;
                }
                
            }
            #{ $self }-text{
                margin-bottom: 20px;
            }
        }
    }

}