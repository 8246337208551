.page-referbycentre-selectdoctor{
    &--centre{
        @include for-desktop-up{
            padding-right: 40px;
            border-right: 1px solid $grey_border;
            .card-contact{
                margin-top: 0;
            }
        }
        h3{
            margin-bottom: 15px;
        }
        &-content{
            @media screen and (min-width: 1201px) and (min-height: 940px){
                position: sticky;
                top: 15px
            }
        }
    }
    &--content{
        h3{
            margin-bottom: 15px;
        }
        @include for-desktop-up{
            padding-left: 40px;
            padding-top: 0;
        }
    }
    &--filter{
        .form-group{
            margin-bottom: 0;
        }
    }
}
