.page-connected-apps{
    &--foxo{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        svg{
            width: 80px;
            height: auto;
            margin-bottom: 15px;
        }
        figure{
            width: 230px;
            margin-bottom: 15px;
            &::after{
                padding-bottom: 25%;
            }
        }
        &-synced{
            width: 100%;
            border: 1px solid $grey_border;
            border-radius: 5px;
            padding: 15px;
            margin-top: 20px;
            &-email{
                font-size: 1.166rem;
                font-weight: 700;
                margin-bottom: 10px;
            }
        }
        .btn-primary{
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .btn-outline{
            height: 35px;
        }
    }
}