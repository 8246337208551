.page-preferences{
    .user-summary{
        @include for-desktop-up{
            position: sticky;
            top: 15px
        }
    }
    &--list{
        @include for-desktop-up{
            padding-left: 40px;
            padding-top: 0;
        }
        &-intro{
            margin-bottom: 10px;
        }
    } 

}