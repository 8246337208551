.user-summary{
    &--info{
        display: flex;
        align-items: center;
        @include for-desktop-up{
            flex-direction: column;
        }
    }
    &--figure{
        width: 20%;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        &::after{
            padding-bottom: 100%;
        }
        @include for-desktop-up{
            border-radius: 24px;
            width: 100%;
            margin-right: 0;
            margin-bottom: 25px;
        }
    }
    &--content{
        width: calc(80% - 16px);
        flex: 1;
        @include for-desktop-up{
            width: 100%;
        }
    }
    &--title{
        font-size: 2.167rem;
        line-height: 2.66rem;
        font-weight: 700;
        margin-bottom: 5px;
    }
    &--subtitle{
        color: $grey_subtitle;
        margin-bottom: 10px;
        font-size: 1.333rem;
        line-height: 1.6rem;
        font-weight: 700;
    }
    &--tags{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &--actions{
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        .btn{
            justify-content: center;
            flex: initial;
            &:not(:last-child){
                margin-bottom: 5px;
                margin-right: 0;
            }
        }
        @include for-phone-up{
            flex-direction: row;
            .btn{
                flex: 1;
                &:not(:last-child){
                    margin-right: 5px;
                    margin-bottom: 0;
                }
            }
        }
        @include for-desktop-up{
            flex-direction: column;
            .btn{
                flex: initial;
                &:not(:last-child){
                    margin-bottom: 5px;
                    margin-right: 0;
                }
            }
        }
    }
}