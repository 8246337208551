.page-more{
    &--menu{
        @include for-tablet-portrait-up{
            &.flush{
                margin-left: 0;
                margin-right: 0;
            }
        }
        a.menu-item{
            padding-left: $padding_default_m;
            padding-right: $padding_default_m;
            @include for-tablet-portrait-up{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &--version{
        padding-left: $padding_default_m;
        font-size: 0.8rem;
        font-weight: 700;
    }
}