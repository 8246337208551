.register-login-prompt{
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 20px;
    border: 1px solid #979797;
    border-radius: 20px;
    @media screen and (min-width: 960px) {
        padding: 80px;
        border-radius: 40px;
    }
    @media screen and (min-width: 1500px) {
        padding: 80px;
        border-radius: 40px;
        width: 80%;
        margin: 0 auto;
    }
}
.register-login-prompt--inner{
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    --gap: 40px;
    gap: var(--gap);
    @media screen and (min-width: 1300px) {
        flex-flow: row nowrap;
        --gap: 80px;
        gap: var(--gap);
        align-items: center;
    }
}

.register-login-prompt--text{
    h2{
        color: #404C59;
        font-size: clamp(1.75rem, 5vw, 2.6rem);
        margin-bottom: 0.8em;
        line-height: 1em;
    }
    p{
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 2em;
    }
    &-buttons{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .btn{
            @media screen and (max-width: 500px) {
                width: 100%;
                justify-content: center;
            }
        }

    }
    @media screen and (min-width: 1300px) {
        flex: 0 0 auto;
        width: calc((100% - (var(--gap))) / 2);
    }
}

.register-login-prompt--items{
    display: flex;
    flex-flow: column nowrap;
    gap: 6px;
    @media screen and (min-width: 1300px) {
        flex: 0 0 auto;
        width: calc((100% - (var(--gap))) / 2);
    }
}