@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);


html{
	font-size: 12px;
}
body{
	font-family: $base-font;
	font-weight: 400;
	color: $black;
	font-size: 1.16rem;
	line-height: 1.75rem;
}
h1{
	font-size: 2.167rem;
	line-height: 2.66rem;
	font-weight: 700;
	margin: 0;
	&.blue{
		color: $blue;
	}
}

h2{
	font-size: 1.833rem;
	line-height: 2rem;
	font-weight: 700;
	margin: 0;
	&.blue{
		color: $blue;
	}
}
h3{
	font-size: 1.333rem;
	line-height: 1.6rem;
	font-weight: 700;
	margin: 0;
	&.blue{
		color: $blue;
	}
}

h4{
	font-size: 1.333rem;
	line-height: 1.6rem;
	font-weight: 600;
	margin: 0;
	&.blue{
		color: $blue;
	}
}

h5{
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	margin: 0;
}

