.header{
    height: $header_height;
    width: 100%;
    background-color: $white;
    @include for-tablet-portrait-up{
        height: $header_height_desktop;
    }
    &.fixed{
        position: fixed;
        top:0;
    }
    &.blue{
        background-color: $blue;
    }
    &.blue-dark{
        background-color: $blue_dark;
    }
    &.is-register-page{
        @include for-tablet-landscape-up{
            height: 200px;
        }
        .header--inner{
            @include for-tablet-landscape-up{
                padding-top: 30px;
                align-items: flex-start;
            }
        }
    }
    &--inner{
        @include inner-div;
        display: flex;
        align-items: center;
        height: 100%;
    }
    &--logo{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        position: relative;
        z-index: 2;
        height: 40px;
        svg{
            height: 100%;
            width: auto;
            fill: $blue;
            .blue &, .blue-dark &{
                fill: $white;
            }
        }
        @include for-tablet-portrait-up{
            cursor: pointer;
            height: 50px;
        }
    }
    &--icon{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        margin-left: 15px;
        &-back{
            width: 18px;
            height: auto;
            margin-right: auto;
            margin-left: 0;
        }
        &-close{
            width: 18px;
            height: auto;
            margin-right: 10px;
        }
        &-profile{
            width: 24px;
            height: auto;
            margin-left: 15px;
            &.btn-primary{
                background-color: $blue_dark;
                width: auto;
                height: 50px;
                border-radius: 100px;
                &.purple{
                    background-color: $purple;
                    &:hover{
                        span{
                            color: $yellow;
                        }
                    }
                }
            }
        }
        &-search{
            width: 24px;
            height: auto;
            &.first{
                margin-left: auto;
            }
            &.btn-primary{
                background: rgba($color: $white, $alpha: 0.1);
                width: auto;
                height: 50px;
                border-radius: 100px;
                justify-content: flex-start;
                padding-left: 25px;
                color: rgba($color: $white, $alpha: 0.5);
                svg{
                    width: 17px;
                    height: auto;
                    fill: $white;
                }
                @include for-tablet-portrait-up{
                    &:hover{
                        svg{
                            fill: $yellow;
                        }
                        color: $yellow;
                    }
                }
            }
        }
        &-favourites{
            width: 26px;
            height: auto;
        }
        svg{
            width: 24px;
            height: 24px;
            fill: $blue;
            .blue &{
                fill: $white;
            }
        }
        span{
            color: $blue;
            @include for-tablet-only{
                font-size: 10px;
            }
            .blue &{
                color: $white;
            }
        }
        &.text-yellow{
            &:not(.header--icon-search){
                svg{
                    .blue &{
                        fill: $yellow;
                    }
                }
            }
            
        }
    }
    &--favourites{
        margin-left: auto;
        margin-right: 20px;
        &-list{
            display: flex;
            .card-doctor-circular{
                width: 40px;
                margin-top: 0;
                margin-left: 10px;
                @include for-tablet-portrait-up{
                    width: 50px;
                }
                &:hover{
                    .card-doctor-circular--figure{
                        @include for-tablet-portrait-up{
                            background-color: $yellow;
                        }
                    }
                }
            }
        }
    }
    &--buttons{
        display: flex;
        flex-flow: row;
        gap: 15px;
    }
    .submenu{
        background-color: $white;
        display: flex;
        flex-direction: column;
        padding: 30px 40px;
        border-radius: 24px;
        box-shadow: 0px 0px 10px 3px rgba($black, 0.26);
        cursor: initial;
        z-index: 2;
        &--item{
            padding: 5px 0;
            white-space: nowrap;
            text-decoration: none;
            &:hover, &.active{
                color: $purple;
                text-decoration: underline;
            }
        }
    }
}

.header-search{
    position: relative;
    .form-group{
        margin-bottom: 0;
    }
    .form-input{
        padding: 9px 40px 9px 10px;
        border: 0;
        border-radius: 6px;
    }
    .btn{
        position: absolute;
        top: 0;
        right: 0;
        height: 38px;
        width: 38px;
        justify-content: center;
    }
}
