.page-become-doctor{
    &--intro{
        h3{
            margin-bottom: 16px;
        }
    }
    &--form{
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
}