.page-cpd{
    &--list{
        @include for-tablet-portrait-up{
            margin-top: -20px;
        }
        @include for-desktop-up{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            .card{
                width: calc((100% - 20px)/2);
                &:nth-child(2n){
                    margin-left: 20px;
                }
            }
        }
    }
    &--time-logged{
        &-text{
            display: flex;
            font-size: 1.33rem;
            &-title{
                font-weight: 700;
                margin-right: 5px;
            }
            &-value{
                font-weight: 500;
            }
        }
        &-description{
            color: $grey_subtitle
        }
    }
}