.card-cta-global{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    background-color: $grey_light;
    border-radius: 10px;
    padding: 30px 20px;
    text-decoration: none;
    color: $black;
    width: 100%;
    &--icon{
        width: 40px;
        svg{
            width: 100%;
            height: auto;
            fill: $blue;
        }
    }
    &--content{
        padding: 0 15px;
        flex: 1;
    }
    &--title{
        font-weight: 600;
        font-size: 1.33rem;
        line-height: 1.8rem;
        padding-bottom: 10px;
    }
    &--description{
        font-weight: 600;
        .has-more{
            padding-bottom: 10px;
        }
        p{
            padding: 0;
        }
    }
    &--more{
        width: 15px;
        align-self: flex-end;
        svg{
            width: 15px;
            height: auto;
            fill: $blue;
        }
    }
}