.filter{
    &--actions{
        .btn{
            border-radius: 6px;
            &:last-child{
                @include for-tablet-only{
                    margin-top: 10px;
                }
            }
        }
    }
    &.in-page{
        background-color: $grey_light;
        border-radius: 12px;
        padding: 20px;
        max-height: 2000px;
        height: auto;
        transition: max-height 0.2s ease-in-out;
        .filter--form{
            display: flex;
            flex-wrap: wrap;
            margin-right: -10px;
            .form-group{
                
            }
            .form-group, .filter--actions{
                @include for-tablet-portrait-up{
                    width: calc((100% - 20px) / 2);
                }
                @include for-tablet-landscape-up{
                    width: calc((100% - 30px) / 3);
                }
                @include for-desktop-up{
                    width: calc((100% - 40px) / 4);
                }
                flex-grow: 1;
                margin-right: 10px;
                margin-bottom: 0;
                margin-top: 20px;
            }
            .filter--actions{
                align-self: flex-end;
            }
        }
        &.section{
            &:not(.collapsed){
                &:first-of-type{
                    padding-top: 20px;
                }
            }
        }
        &.collapsed{
            max-height: 0;
            padding: 0;
            overflow: hidden;
            h2{
                visibility: hidden;
                display: none;
            }
            .filter--form{
                visibility: hidden;
                display: none;
            }
            .filter--actions{
                visibility: hidden;
                display: none;
            }
            + .section{
                padding-top: 0;
            }
        }
        h2{
            line-height: 1;
        }
        .form-label{
            font-size: 1rem;
            font-weight: 600;
            line-height: 1;
        }
        .form-group input[type="text"], .form-group input[type="number"], .form-group input:not([type]) {
            height: 50px;
        }
        .form-select-container .form-select__input input{
            height: 36px;
        }
        .filter--actions{
            @include for-tablet-portrait-up{
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                .btn{
                    height: 50px;
                    width: calc((100% - 10px) / 2);
                    padding: 0 5px;
                    &:nth-child(2n){
                        margin-left: 10px;
                    }
                }
            }
        }
        

    }
}