.card-download{
    
    background-color: $grey_light;
    padding: 20px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: 10px;
    height: 100%;
    text-decoration: none;
    color: $black;
    
    &--figure{
        width: 25%;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 14px;
        background-color: darken($grey_light, 3);
        text-align: center;
        &::after{
            padding-bottom: 100%;
        }
        &-svg{
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $blue;
            &.has-icon{
                background-color: transparent;
            }
            svg{
                width: 70%;
                height: auto;
            }
        }
    }
    &--content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        padding-right: 14px;
    }
    &--icon{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 28px;
        svg{
            fill: $black;
            width: 28px;
            height: auto;
           
        }
    }
}