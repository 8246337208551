.page-referpatient-singledoctor{
    &--page-content{
        height: 100%;
        .page-content--inner{
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
    &--selection{
        @include for-desktop-up{
            padding-right: 40px;
            .card-contact{
                margin-top: 0;
            }
        }
        &-content{
            @include for-desktop-up{
                position: sticky;
                top: 15px
            }
        }
        h3{
            margin-bottom: 15px;
        }
        &-centre{
            margin-bottom: 30px;
        }
        &-specialist{
            .card-cta--title{
                line-height: 1;
                align-items: flex-start;
            }
        }
    }
    &--content{
        @include for-desktop-up{
            padding-left: 40px;
            padding-top: 0;
        }
    }
    &--actions{
        display: flex;
        padding-left: $padding_default_m;
        padding-right: $padding_default_m;
        margin-left: -$padding_default_m;
        margin-right: -$padding_default_m;
        background-color: $grey_light;
        position: sticky;
        bottom: 0;
        margin-bottom: -20px;
        margin-top: auto;
        border-top: 1px solid #EAEBEC;
        @include for-tablet-only{
            bottom: 80px;
        }
        @include for-tablet-landscape-up{
            padding-left: $padding_default_d;
            padding-right: $padding_default_d;
            margin-left: -$padding_default_d;
            margin-right: -$padding_default_d;
            bottom: 0;
            margin-bottom: -45px;
        }
        &-start{
            width: 100%;
            @include for-desktop-up{
                width: 60%;
                padding-left: 40px;
                margin-left: auto;
            }
        }
        .btn{
            justify-content: center;
        }
    }
    &--tabs{
        padding-top: 20px !important;
    }
}
