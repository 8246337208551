.tabs{
    display: flex;
    width: 100%;
    border: 1px solid $black;
    border-radius: 4px;
    @include for-tablet-portrait-up{
        border: none;
        border-radius: 0;
    }
    &--item{
        font-size: 1rem;
        font-weight: 600;
        line-height: 1;
        border-left: 1px solid $black;
        align-items: center;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 15px;
        cursor: pointer;
        @include for-tablet-portrait-up{
            border: none;
            border-bottom: 1px solid $white;
            flex: inherit;
            margin-left: 30px;
            font-size: 1.333rem;
            padding: 7px 0;
        }
        &:first-child{
            border-left: none;
            @include for-tablet-portrait-up{
                margin-left: 0;
            }
        }
        &.active{
            background-color: $black;
            color: $white;
            @include for-tablet-portrait-up{
                border-bottom: 1px solid $blue;
                color: $blue;
                background-color: transparent;
            }
        }
        &:hover{
            @include for-tablet-portrait-up{
                border-bottom: 1px solid $blue;
                color: $blue;
            }
        }
        &.is-disabled{
            opacity: 0.5;
            pointer-events: none;
            border-bottom: 1px solid $black;
        }
    }
}