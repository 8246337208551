.page-edit-profile{
    .user-summary{
        @include for-desktop-up{
            position: sticky;
            top: 15px
        }
    }
    form{
        h2{
            margin-bottom: 25px;
        }
    }
}