.card-ctasearch{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    text-decoration: none;
    color: $black;
    cursor: pointer;
    @include for-tablet-portrait-up{
        flex-direction: row;
        background-color: $grey_light;
        border-radius: 8px;
        align-items: center
    }
    &--figure{
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
        &::after{
            padding-bottom: 100%;
        }
        @include for-tablet-portrait-up{
            width: 40%;
            border-radius: 0;
        }
    }
    &--content{
        padding-top: 15px;
        @include for-tablet-portrait-up{
            padding-top: 0;
            padding-left: 15px;
        }
    }
    &--title{
        font-weight: 600;
    }
}