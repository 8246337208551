// Media queries
@mixin for-phone-only {
	@media (max-width: 480px) {
	  @content;
	}
}
@mixin for-phone-up {
	@media (min-width: 480px) {
	  @content;
	}
}
@mixin for-tablet-portrait-up {
	@media (min-width: 768px) {
	  @content;
	}
  }
@mixin for-tablet-only {
	@media (max-width: 767px) {
	  @content;
	}
  }
  @mixin for-tablet-landscape-up {
	@media (min-width: 1024px) {
	  @content;
	}
  }
  @mixin for-tablet-landscape-only {
	@media (max-width: 1200px) {
	  @content;
	}
  }
  @mixin for-desktop-up {
	@media (min-width: 1201px) {
	  @content;
	}
  }
  @mixin for-big-desktop-up {
	@media (min-width: 1536px) {
	  @content;
	}
  }
  @mixin for-extra-big-desktop-up {
	@media (min-width: 1660px) {
	  @content;
	}
  }
  
  //inner div center
  
  @mixin inner-div {
	max-width: $content_width_max;
	padding: 0px $padding_default_m;
	margin: 0 auto;
	width: 100%;
	@include for-tablet-landscape-up{
		padding: 0 $padding_default_d;
	}
  }