.page-ct-list{
    &--content{
        &-list{
            .card-clinical-trial{
                &:first-of-type{
                    @include for-phone-only{
                        margin-top: 0;
                    }
                    
                }
            }
            .infinite-scroll-component{
                @include for-phone-up{
                    display: flex;
                    flex-wrap: wrap;
                    .card-clinical-trial{
                        width: calc((100% - 20px)/2);
                        &:nth-child(2n){
                            margin-left: 20px;
                        }
                    }
                }
                @include for-desktop-up{
                    display: flex;
                    flex-wrap: wrap;
                    .card-clinical-trial{
                        width: calc((100% - 40px)/3);
                        &:nth-child(2n){
                            margin-left: 0;
                        }
                        &:nth-child(3n -1){
                            margin-left: 20px;
                            margin-right: 20px;
                        }
                    }
                }
            }
            @include for-phone-up{
                margin-top: -20px;
            }
        }
    }
    &--filter-selected-btns{
        margin-bottom: 5px;
    }
}
