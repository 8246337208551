.btn{
    display: flex;
    padding: 0 30px;
    align-items: center;
    height: 50px;
    font-size: 1.16rem;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    border-radius: 10px;
    color: $blue;
    transition: all .2s ease;
    &:focus{
        outline: none;
    }
    &:active{
        opacity: 0.7;
    }
    &:disabled{
        cursor: text;
        opacity: 0.7;
        pointer-events: none;
    }
    &.disabled{
        cursor: text;
        opacity: 0.7;
        pointer-events: none;
    }
    svg{
        width: 16px;
        height: auto;
        fill: $blue;
        pointer-events: none;
        transition: all .2s ease;
    }
    span{
        margin: 0 10px;
        pointer-events: none;
    }
    &.pill{
        border-radius: 50px;
    }
    &-primary{
        background-color: $blue;
        color: $white;
        border: 1px solid $blue;
        &.white{
            background-color: $white;
            color: $blue;
            border: 1px solid $white;
            &:hover{
                @include for-tablet-portrait-up{
                    background-color: $blue;
                }
            }
        }
        &.green{
            background-color: $green;
            border: 1px solid $green;
        }
        &.purple{
            background-color: $purple;
            border: 1px solid $purple;
            color: $white;
        }
        &.grey{
            background-color: $grey_light;
            border: 1px solid $grey_light;
        }
        svg{
            fill: $white;
        }
        &:hover{
            @include for-tablet-portrait-up{
                svg{ 
                    fill: $yellow;
                }
                color: $yellow;
            }
            &.text-red{
                background-color: $grey;
            }
        }
    }
    &-outline{
        background: none;
        color: $black;
        border: 1px solid $black;
        &:hover{
            @include for-tablet-portrait-up{
                background: $black;
                color: $white;
                border: 1px solid $black;
                svg{
                    fill: $white;
                }
            }
        }
        &.white{
            color: $white;
            border: 1px solid $white;
            &:hover{
                @include for-tablet-portrait-up{
                    background: $white;
                    color: $blue;
                    border: 1px solid $white;
                }
            }
        }
        &.blue{
            color: $blue;
            border: 1px solid $blue;
            &:hover{
                @include for-tablet-portrait-up{
                    background: $blue;
                    color: $white;
                    border: 1px solid $blue;
                }
            }
        }
        &.red{
            color: $red;
            border: 1px solid $red;
            &:hover{
                @include for-tablet-portrait-up{
                    background: $red;
                    color: $white;
                    border: 1px solid $red;
                }
            }
        }
        &.green{
            color: $green;
            border: 1px solid $green;
            &:hover{
                @include for-tablet-portrait-up{
                    background: $green;
                    color: $white;
                    border: 1px solid $green;
                }
            }
        }
        &.purple{
            color: $purple;
            border: 1px solid $purple;
            &:hover{
                @include for-tablet-portrait-up{
                    background: $purple;
                    color: $white;
                    border: 1px solid $purple;
                }
            }
        }
    }
    &-plain{
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
        height: auto;
    }
    &-icon{
        svg{
            margin-left: 0;
        }
    }
    &-text{
        text-decoration: none;
        color: $blue;
    }
    &.text-white{
        color: $white;
        svg{
            fill: $white;
        }
    }
    &.text-yellow{
        color: $yellow;
        svg{
            fill: $yellow;
        }
        &:hover{
            @include for-tablet-portrait-up{
                svg{ 
                    fill: $white;
                }
                color: $white;
            }
        }
    }
    &.text-green{
        color: $green;
        svg{
            fill: $green;
        }
    }
    &.text-purple{
        color: $purple;
        svg{
            fill: $purple;
        }
    }
    &.text-black{
        color: $black;
        svg{
            fill: $black;
        }
    }
    &.text-red{
        color: $red;
        svg{
            fill: $red;
        }
    }
    &.text-blue{
        color: $blue;
        svg{
            fill: $blue;
        }
    }
    &.underline{
        text-decoration: underline;
    }
    &.center{
        justify-content: center;
    }
    &.full-width{
        width: 100%;
        justify-content: center;
    }
    &.full-width-d{
        @include for-tablet-portrait-up{
            width: 100%;
            justify-content: center;
        }
    }
    &.full-width-m{
        @include for-tablet-only{
            width: 100%;
            justify-content: center;
        }
    }
    &.has-icon-text{
        svg{
            &:first-of-type{
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }
    
}
