.bottom-menu{
    height: $bottom_menu_height;
    width: 100%;
    background-color: $white;
    box-shadow: 0px -2px 6px 0px rgba(10,10,10,0.1);
    position: fixed;
    bottom: 0;
    z-index: 1;
    &--inner{
        @include inner-div;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &--item{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $black;
        font-size: 10px;
        text-decoration: none;
        svg{
            height: 28px;
            width: auto;
            fill: $grey_subtitle;
        }
        &.active{
           color: $blue;
           svg{
               fill: $blue;
           }
        }
    }
}