.page-forgot-password{
    &--head{
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        @include for-tablet-portrait-up{
            padding-top: 35px;
        }
    }
    &--content{
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}