figure{
    width: 100%;
    position: relative;
    animation: show-image 0.2s;
    &::after{
        content: "";
        display: block;
        padding-bottom: 61%;
    }
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
@keyframes show-image {
    0% {opacity:0;}
    100% {opacity:1;}
}