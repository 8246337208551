.page-account{
    &--header{
        @include for-tablet-only{
            &.section{
                padding-top: 35px;
            }
        }
    }
    &--menu{
        @include for-tablet-portrait-up{
            &.flush{
                margin-left: 0;
                margin-right: 0;
            }
        }
        @include for-desktop-up{
            padding-left: 40px;
            padding-top: 0;
        }
        .section--inner{
            margin-top: 27px;
        }
        .menu-item{
            padding-left: $padding_default_m;
            padding-right: $padding_default_m;
            @include for-tablet-portrait-up{
                padding-left: 0;
                padding-right: 0;
            }
        }
        .btn{
            margin-top: 27px;
            margin-left: $padding_default_m;
        }
    }
}