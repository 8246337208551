.page-discuss-patient{
    &--content{
        &-list{
            .card{
                &:first-of-type{
                    margin-top: 0;
                }
            }
        }
    }
}