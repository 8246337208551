
.page{
    &--placeholder-message{
        font-weight: bold;
        padding-top: 30px
    }
}
@import 'pages/start';
@import 'pages/login';
@import 'pages/register';
@import 'pages/forgot-password';
@import 'pages/reset-password';
@import 'pages/register-foxo';
@import 'pages/register-success';
@import 'pages/account';
@import 'pages/account-profile';
@import 'pages/account-edit-profile';
@import 'pages/account-preferences';
@import 'pages/account-change-password';
@import 'pages/account-connected-apps';
@import 'pages/account-marketing';
@import 'pages/account-cpd-list';
@import 'pages/account-favourites';
@import 'pages/feeds';
@import 'pages/more';
@import 'pages/doctor-single';
@import 'pages/doctors-list';
@import 'pages/doctors-closest';
@import 'pages/find-doctor';
@import 'pages/discuss-patient';
@import 'pages/education';
@import 'pages/search';
@import 'pages/article-single';
@import 'pages/articles-list';
@import 'pages/clinical-resources';
@import 'pages/refer-patient';
@import 'pages/refer-patient-bycentre-selectcentre';
@import 'pages/refer-patient-bycentre-selectdoctor';
@import 'pages/refer-patient-singledoctor';
@import 'pages/refer-patient-refer';
@import 'pages/learning-modules';
@import 'pages/learning-modules-single';
@import 'pages/events';
@import 'pages/events-single';
@import 'pages/events-register-success';
@import 'pages/treatment-pathways';
@import 'pages/treatment-techniques';
@import 'pages/news';
@import 'pages/research';
@import 'pages/clinical-articles';
@import 'pages/centres-list';
@import 'pages/generic';
@import 'pages/report-issue';
@import 'pages/become-doctor';
@import 'pages/clinical-trials-list';
@import 'pages/clinical-trials-single';
@import 'pages/welcome';
@import 'pages/practice_based';