.wb-share{
    $self: &;
    display: flex;
    align-items: center;
    &--title{
        margin-right: 14px;
        color: $blue;
    }
    &--item{
        width: 48px;
        height: 48px;
        border: 1px solid $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 9px;
        svg{
            width: 40%;
            height: auto;
            fill: $blue;
        }
        &:hover{
            border-width: 3px;
        }
    }
    &.purple{
        #{ $self }--title{
           color: $purple;
        }
        #{ $self }--item{
            border-color: $purple;
            svg{
                fill: $purple;
            }
        }
    }
    &.green{
        #{ $self }--title{
           color: $green;
        }
        #{ $self }--item{
            border-color: $green;
            svg{
                fill: $green;
            }
        }
    }
}