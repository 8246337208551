.page-referbycentre-selectcentre{
    &--filter{
        @include for-desktop-up{
            padding-right: 40px;
            border-right: 1px solid $grey_border;
            .form-group{
                width: 100%;
                &:nth-child(2n){
                    margin-left: 0;
                }
            }
        }
        .filter--content{
            @media screen and (min-width: 1201px) and (min-height: 940px){
                position: sticky;
                top: 15px
            }
        }
        .filter--actions{
            flex-direction: column;
            .btn{
                width: 100%;
                &:nth-child(2n){
                    margin-right: 0;
                }
                &:last-child{
                    margin-top: 10px;
                }
            }
        }
    }
    &--list{
        @include for-desktop-up{
            padding-left: 40px;
            padding-top: 0;
        }
    }
    &--favourites{
        margin-bottom: 50px;
        .horizontal-scoller{
            width: 100%;
            margin-top: 20px;
            align-items: center;
            &--content{
                max-width: 900px;
                .card-doctor-circular{
                    flex: 0 0 auto;
                    margin-right: 50px;
                    margin-left: 0;
                    margin-top: 0;
                    width: 90px;
                    &--title{
                        font-size: 1rem;
                        line-height: 1.2;
                    }
                }
            }
            &--icon{
                height: 28px;
                width: auto;
            }
        }
    }
}
