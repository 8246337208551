.card-cta{
    $self : &;
    overflow: hidden;
    border-radius: 10px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    position: relative;
    &.is-disabled{
        opacity: 0.6;
        #{$self}--main{
            cursor: default;
        }
    }
    .attached-list &{
        margin-top: 0;
        border-radius: 0;
        &:first-of-type{
            border-radius: 10px 10px 0 0;
        }
        &:last-of-type{
            border-radius: 0 0 10px 10px;
            &::after{
                content: none;
            }
        }
        &::after{
            content: "";
            height: 1px;
            width: calc(100% - 40px);
            position: absolute;
            bottom: 0;
            left: 20px;
            background-color: $grey_border;
        }
    }
    &--main{
        background-color: $grey_light;
        padding: 20px 25px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        height: 100%;
        text-decoration: none;
        color: $black;
        &.is-centered{
            align-items: center;
        }
        &.is-bordered{
            background-color: $white;
            border: 1px solid $grey;
            border-radius: 10px;
        }
        &.is-clickable{
            cursor: pointer;
        }
        &.is-small{
            #{$self}--figure{
                max-width: 80px;
            }
        }
        &.has-icon-bg{
            padding-right: 40px;
            #{$self}--content{
                padding-right: 14px;
            }
        }
        &.has-icon-nobg{
            #{$self}--icon{
                width: 14px;
                right: 25px;
            }
        }
    }
    &--figure{
        width: 25%;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 14px;
        &::after{
            padding-bottom: 100%;
        }
        &-svg{
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: darken($grey_light, 3);
            svg{
                width: 25%;
                height: auto;
            }
            &.blue{
                background-color: $blue;
                svg{
                    fill: $white;
                }
            }
            &.purple{
                background-color: $purple;
                svg{
                    fill: $white;
                }
            }
            &.green{
                background-color: $green;
                svg{
                    fill: $white;
                }
            }
            &.yellow{
                background-color: $yellow;
                svg{
                    fill: $white;
                }
            }
            &.big{
                svg{
                    width: 70%;
                }
            }
        }
    }
    &--content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
    }
    &--tags{
        margin-bottom: 5px;
        display: flex;
        flex-wrap: wrap;
        .text{
            margin-bottom: 5px;
        }
    }
    &--title{
        &:not(:last-child){
            margin-bottom: 5px;
        }
        display: flex;
        align-items: center;
        svg{
            height: 14px;
            width: auto;
            fill: $blue;
            margin-left: 10px;
        }
    }
    &--icon{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 40px;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        .is-bordered &{
            top: -1px;
            height: calc(100% + 2px);
            right: -1px;
        }
        svg{
            fill: $blue;
            width: 14px;
            height: auto;
            &.green{
                fill: $green;
            }
            &.big{
                width: 28px;
                margin-right: 12px;
            }
        }
        &.blue{
            background-color: $blue;
            svg{
                fill: $yellow;
            }
        }
        &.yellow{
            background-color: $yellow;
            svg{
                fill: $black;
            }
        }
        &.green{
            background-color: $green;
            svg{
                fill: $yellow;
            }
        }
    }
    &--specialinterests{
        padding: 10px 24px;
        &-title{
            display: flex;
            align-items: center;
            cursor: pointer;
            svg{
                fill: $blue;
                width: 14px;
                height: auto;
                margin-left: 8px;
            }
        }
        &-content{
            padding-top: 5px;
            display: flex;
            flex-wrap: wrap;
            .text{
                margin-right: 4px;
                font-size: 1rem;
                font-weight: 700;
                margin-top: 5px;
            }
        }
    }
}