.page-event-single{
    &--header{
        @include for-tablet-landscape-only{
            display: flex;
            flex-direction: column-reverse;
        }
        @include for-tablet-only{
            margin-left: -$padding_default_m;
            margin-right: -$padding_default_m;
        }
        @include for-desktop-up{
            border-bottom: 1px solid $grey_border;
            padding-bottom: 55px;
        }
    }
    &--details{
        @include for-tablet-landscape-only{
            background-color: $black;
            color: $white;
            padding: 35px 25px;
        }
        @include for-tablet-only{
            padding: 35px 20px;
        }
        flex: 1;
        h1{
            margin-bottom: 20px;
        }
        .text{
            font-size: 1.33rem;
            font-weight: 700;
            line-height: 1.66rem;
            justify-content: flex-start;
            margin-bottom: 6px;
            color: $white;
            padding: 5px 0;
            @include for-desktop-up{
                background-color: $grey_light;
                padding: 20px 30px;
                border-radius: 12px;
                color: $black;
            }
            svg{
                height: 20px;
                margin-right: 13px;
                fill: $white;
                @include for-desktop-up{
                    fill: $black;
                }
            }
        }
        .btn{
            margin-bottom: 6px;
            &:first-of-type{
                margin-top: 35px;
                @include for-desktop-up{
                    margin-top: 0;
                }
            }
            &.white{
                @include for-desktop-up{
                    color: $blue;
                    border-color: $blue;
                    &:hover{
                        background-color: $blue;
                        color: $white;
                    }
                }
            }
            
        }
    }
    &--share{
        &.section{
            padding-bottom: 0;
        }
        margin-top: 20px;
        &.purple{
            @include for-tablet-landscape-only{
                .wb-share--title{
                    color: $white;
                }
                .wb-share--item{
                    border-color: $white;
                    svg{
                        fill: $white;
                    }
                }
            }
        }
    }
    &--image{
        @include for-desktop-up{
            padding-left: 40px;
        }
        figure{
            overflow: hidden;
            @include for-tablet-landscape-only{
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
            @include for-tablet-only{
                border-radius: 0;
            }
            @include for-desktop-up{
                height: 100%;
                border-radius: 12px;
            }
            &::after{
                @include for-desktop-up{
                    content: none;
                }
            }
        }
    }
    &--description{
        @include for-desktop-up{
            width: 70%;
        }
    }
    &--speakers{
        &-cards{
            @include for-desktop-up{
                display: flex;
                flex-wrap: wrap;
                gap: 2%;
            }
            .card{
                @include for-desktop-up{
                    width: 49%;
                    flex: 0 0 auto;
                }
            }
        }
    }
    &--links{
        display: flex;
        flex-direction: column;
    }
    &--content-section{
        padding-bottom: 20px;
        h3{
            padding-bottom: 10px;
        }
    }
    &--register{
        $self: &;
        background-color: $grey_light;
        border-radius: 12px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 20px;
        padding-top: 35px;
        max-height: 2000px;
        height: auto;
        transition: max-height 0.2s ease-in-out;
        position: relative;
        margin-top: 35px;
        @include for-desktop-up{
            margin-top: 55px;
        }
        &.collapsed{
            max-height: 0;
            padding: 0;
            overflow: hidden;
            .btn{
                visibility: hidden;
                display: none;
            }
            .form-group{
                visibility: hidden;
                display: none;
            }
            #{$self}-close{
                visibility: hidden;
                display: none;
            }

        }
        .form-label{
            color: $purple;
        }
        &-close{
            position: absolute;
            right: 5px;
            top: 5px;
            height: 35px;
            width: 35px;
            padding: 5px;
            cursor: pointer;
            svg{
              fill: $purple;
              width: 100%;
              height: auto;
            }
            &:active{
              opacity: 0.7;
            }
          }
    }
    iframe{
        margin-top: 30px;
    }
    .loading{
        margin-top: 30px;
    }
}