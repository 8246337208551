.page-lm-single{
    &--page-content{
        height: 100%;
        .page-content--inner{
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
    &--details{
        @include for-desktop-up{
            padding-right: 30px;
        }
        .progress-bar{
            margin-bottom: 30px;
        }
    }
    &--tags{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 30px;
    }
    &--description{
        margin-bottom: 50px;
    }
    &--right{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        @include for-desktop-up{
            padding-left: 30px;
            margin-top: -20px;
        }
        .card-cta{
            &--figure-svg{
                svg{
                    fill: $green;
                }
            }
        }
    }
    &--actions{
        display: flex;
        padding-left: $padding_default_m;
        padding-right: $padding_default_m;
        margin-left: -$padding_default_m;
        margin-right: -$padding_default_m;
        background-color: $grey_light;
        position: sticky;
        bottom: 0;
        margin-bottom: -20px;
        margin-top: auto;
        border-top: 1px solid #EAEBEC;
        @include for-tablet-only{
            bottom: 80px;
        }
        @include for-tablet-landscape-up{
            padding-left: $padding_default_d;
            padding-right: $padding_default_d;
            margin-left: -$padding_default_d;
            margin-right: -$padding_default_d;
            bottom: 0;
            margin-bottom: -45px;
        }
        @include for-phone-only{
            flex-direction: column-reverse;
        }
        &-start{
            width: 50%;
            padding-left: 25px;
            .btn{
                width: 100%;
            }
            @include for-phone-only{
                padding-left: 0;
                width: 100%;
                padding-bottom: 10px;
            }
        }
        &-navs{
            width: 50%;
            padding-right: 25px;
            display: flex;
            .btn{
                width: 48%;
                &:last-of-type{
                    margin-left: 4%;
                }
            }
            @include for-phone-only{
                padding-right: 0;
                width: 100%;
            }
        }
        .btn{
            justify-content: center;
        }
    }
}