.card-contact{
    overflow: hidden;
    border-radius: 10px;
    background-color: $grey_light;
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 15px;
    &--figure{
        width: 25%;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 14px;
        align-self: flex-start;
        &::after{
            padding-bottom: 100%;
        } 
    }
    &--content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        align-self: center;
    }

    &--title{
        &.is-minimal{
            align-self: center;
        }
        &:not(.is-minimal){
            margin-bottom: 10px;
            font-size: 2.1rem;
            line-height: 2.75rem;
            font-weight: 700;
            border-bottom: 1px solid $grey_border;
            width: 100%;
            padding-bottom: 5px;
        }
    }
    &--item{
        &-label{
            font-size: 0.8333;
            font-weight: 500;
            color: $blue;
        }
        &-value{
            font-weight: 700;
            cursor: pointer;
            &:active{
                opacity: 0.7;
            }
        }
    }
    &--phone{
        &-value{
            font-size: 1.66rem;
            line-height: 2.25rem
        }
    }
    .btn{
        margin-top: 10px;
        min-width: 50%;
        justify-content: center;
    }
}