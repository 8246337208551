.card-cta-subpage{
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: flex-start;
    background-color: $blue_dark;
    border-radius: 12px;
    padding: 30px;
    align-items: center;
    text-decoration: none;
    color: $black;
    cursor: pointer;
    @include for-tablet-landscape-up{
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 20px;
    }
    @include for-desktop-up{
        flex-direction: row;
    }
    &--icon{
        @include for-tablet-portrait-up{
            display: none;
        }
        display: flex;
        align-items: center;
        width: 20px;
        svg{
            width: 100%;
            height: auto;
            fill: $white;
        }
    }
    &--content{
        padding: 0 10px;
        flex: 1;
        @include for-tablet-portrait-up{
            padding: 0;
        }
    }
    &--title{
        font-weight: 700;
        font-size: 1.33rem;
        line-height: 1;
        color: $white;
        @include for-tablet-landscape-up{
            font-size: 1.67rem;
            line-height: 1.2;
            padding-bottom: 20px;
        }
        @include for-desktop-up{
            padding-bottom: 0;
        }
    }
    &--subtitle{
        font-weight: 700;
        font-size: 1.33rem;
        line-height: 1.66rem;
        padding-bottom: 5px;
        color: $yellow;
        display: none;
        @include for-tablet-landscape-up{
            font-size: 1.16rem;
            line-height: 1.4rem;
            display: block;
        }
    }
    &--more{
        width: 16px;
        display: flex;
        align-items: center;
        svg{
            width: 16px;
            height: auto;
            fill: $white;
            @include for-tablet-landscape-up{
                display: none;
            }
        }
        span{
            font-size: 1.08rem;
            font-weight: 700;
            color: $blue;
            display: none;
            @include for-tablet-landscape-up{
                display: block;
            }
        }
        @include for-tablet-landscape-up{
            background-color: $white;
            border-radius: 100px;
            width: auto;
            padding: 10px 30px;
        }
    }
}