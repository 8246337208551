.progress-bar{
    $self: &;
    display: flex;
    flex-direction: column;
    &.has-label-right{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        #{ $self }--line{
            margin-bottom: 0;
            margin-right: 5px;
        }
    }
    &.is-centered{
        align-items: center;
    }
    &--line{
        height: 5px;
        min-width: 50px;
        background-color: $grey;
        border-radius: 100px;
        margin-bottom: 6px;
        width: 100%;
    }
    &--fill{
        height: 100%;
        background-color: $green;
        border-radius: 100px;
    }
    &--label{
        font-size: 0.833rem;
        font-weight: 600;
        color: $green;
        line-height: 1.16rem;
    }
}