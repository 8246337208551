.page-referpatient-refer{
    &--left{
        @include for-desktop-up{
            padding-right: 40px;
        }
    }
    &--selection{
        @include for-desktop-up{
            .card-contact{
                margin-top: 0;
            }
        }
        &-content{
            @media screen and (min-width: 1201px) and (min-height: 940px){
                position: sticky;
                top: 15px
            }
        }
        h3{
            margin-bottom: 15px;
        }
        &-centre{
            margin-bottom: 30px;
        }
        &-specialist{
            .card-cta--title{
                line-height: 1;
                align-items: flex-start;
            }
            &__title{
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 15px;
                h3{
                    margin-bottom: 0;
                };
            }
        }
    }
    &--right{
        &:only-child{
            padding-left: 0;
        }
        @include for-desktop-up{
            padding-left: 40px;
            padding-top: 0;
        }
    }
    &--form{
        h2{
            margin-bottom: 20px;
        }
        &-upload{
            margin-bottom: 10px;
            &-item{
                padding: 10px;
                display: flex;
                border: 1px solid $grey_border;
                border-radius: 5px;
                margin-bottom: 4px;
                align-items: center;
                justify-content: space-between;
                font-weight: 600;
                &-name{
                    width: calc(100% - 150px);
                    word-wrap: break-word;
                }
                .btn{
                    margin: 0;
                    margin-left: 10px;
                    width: 130px;
                }
            }
        }
        &-confirmation{
            &-item{
                margin-bottom: 20px;
                h3{
                    margin-bottom: 10px;
                }
                &-value{
                    border: 1px solid $grey_border;
                    border-radius: 5px;
                    padding: 25px 20px;
                    font-weight: 600;
                    &:not(:first-of-type){
                        margin-top: 5px;
                    }
                    ul{
                        margin-bottom: 0;
                    }
                }
            }
            .btn{
                &:not(:first-of-type){
                    margin-top: 10px;
                }
            }
        }

    }

}
