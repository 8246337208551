.page-register{
    &--head{
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        @include for-tablet-portrait-up{
            padding-top: 35px;
        }
    }
    &--content{
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 35px;
        }
    }
    &--subtitle{
        margin-bottom: 5px;
        color: $grey_subtitle;
        font-weight: 600;
    }
    &--text{
        margin-top: 35px;
        margin-bottom: 15px;
    }
    .form-text-link{
        margin-top: 30px;
        text-align: center;
        color: $black;
        font-weight: 500;
        @include for-phone-only{
            padding-bottom: 35px;
        }
        a{
            color: $black;
        }
    }
    .container-checkbox{
        font-weight: 500;
        font-size: 1.333rem;
    }
    h2{
        margin-bottom: 25px;
    }
    h3{
        margin-bottom: 15px;
    }
}

.register-form-wrap{
    background-color: #eee;
    @include for-tablet-landscape-up{
        padding-bottom: 50px;
    }
}

.register-form-inner{
    max-width: 1800px;
    margin: 0 auto;
    @include for-tablet-landscape-up{
        display: flex;
        flex-flow: row nowrap;
        padding: 0 75px;
    }
}

.register-form-panel{
    background: $white;
    padding: 15px;
    border-radius: 10px;
    @include for-tablet-landscape-up{
        padding: 30px;
        flex: 0 0 50%;
        margin-top: -50px;
    }
}

.register-form-content{
    padding: 25px 15px;
    display: flex;
    flex-flow: column nowrap;
    gap: 25px;
    @include for-tablet-landscape-up{
        flex: 0 0 50%;
        gap: 50px;
        padding: 50px 0px 50px 50px;
    }
}