.card-doctor-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $black;
    max-width: 320px;
    cursor: default;
    &.tooltip{
        background-color: $grey_light;
        border-radius: 6px;
        position: absolute;
        left: 50%;
        top: calc(100% + 17px);
        transform: translateX(-50%);
        z-index: 2;
        padding: 15px;
        width: 220px;
        box-shadow: 0px 2px 3px 0px rgba($black ,0.25);
        &::after{
            content: " ";
            position: absolute;
            bottom: 100%;  /* At the top of the tooltip */
            left: 50%;
            margin-left: -10px;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent $grey_light transparent;
        }
        .btn{
            font-size: 1rem;
            padding: 7px 15px;
            height: auto;
            &.grey{
                border: 1px solid $grey;
            }
        }
        .card-doctor-links--title{
            margin-top: 5px;
            margin-bottom: 15px;
        }
    }
    @include for-tablet-portrait-up{
        width: 320px;
    }
    &--figure{
        width: 130px;
        border-radius: 50%;
        overflow: hidden;
        background-color: $white;
        img{
            border-radius: 50%;
            object-fit: cover;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
        &::after{
           padding-bottom: 100%;
        } 
        &::before{
            content: 'View profile';
            position: absolute;
            background-color: rgba($color: $black, $alpha: 0.6);
            z-index: 2;
            border-radius: 50%;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 600;
            font-size: 1rem;
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s;
        }
        &:hover{
            &:not(.disabled){
                &::before{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &--title{
        margin-top: 10px;
        font-size: 1.16rem;
        line-height: 1;
        text-align: center;
        font-weight: 600;
        margin-bottom: 40px;
        color: $black;
        text-decoration: none;
    }
    .btn{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}