.success--message{
    @include inner-div;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
    h2{
        margin-bottom: 30px;
        text-align: center;
        line-height: 3rem;
    }
    h3{
        margin-bottom: 30px;
        text-align: center;
    }
    &-icon{
        width: 128px;
        height: 128px;
        border: 1px solid rgba($color: $green, $alpha: 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        margin-bottom: 20px;
        &::after{
            content: '';
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 85px;
            height: 85px;
            border: 1px solid rgba($color: $green, $alpha: 0.3);
            border-radius: 50%;
        }
        svg{
            fill: $green;
            width: 50px;
            height: auto;
        }
    }
}