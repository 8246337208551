.page-login{
    &--head{
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        @include for-tablet-portrait-up{
            padding-top: 35px;
        }
    }
    &--content{
        @include for-phone-up{
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .form-group{
        &.row{
            .form-group{
                margin-bottom: 0;
            }
            .btn-text{
                font-weight: 700;
                color: $black;
                margin-left: auto;
                font-size: 14px;
            }
        }
    }
    .form-text-link{
        margin-top: 30px;
        text-align: center;
        color: $black;
        font-weight: 500;
        a{
            color: $black;
        }
    }
}