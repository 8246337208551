.card-featured{
    overflow: hidden;
    border-radius: 10px;
    background-color: $grey_light;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: $black;
    &--content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 18px;
        flex: 1
    }
    &--figure{  
        &::after{
            padding-bottom: 53%;
        } 
    }
    &--description{
        color: $black;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &--title{
        margin-bottom: auto;
        font-size: 1.833rem;
        line-height: 2rem;
        padding-bottom: 10px;
    }
    &--details{
        margin-top: 10px;
        margin-bottom: 20px;
        .text{
           justify-content: flex-start;
           align-items: flex-start;
           line-height: 1;
           &:not(:last-child){
               margin-bottom: 8px;
           }
        }
    }
    &--author{
        margin-bottom: 10px;
    }
    &--tags{
        display: flex;
        flex-wrap: wrap;
        .text{
            margin-bottom: 5px;
        }
    }
    &--stamp{
        position: absolute;
        top: 15px;
        left: 23px;
        background-color: $blue;
        color: $white;
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 0.833rem;
        &.red{
            background-color: $red;
        }
        &.yellow{
            background-color: $yellow;
            color: $black;
        }
    }
}