.btn--favourite {
    $self: &;
    height: 22px;
    width: 22px;
    position: relative;

    svg {
        height: 100%;
        width: auto;
        fill: #000000;
        cursor: pointer;
        transform-origin: center center;

        &.icon-solid {
            fill: $blue;
        }

    }

    .loading {
        height: 100%;
        width: 100%;

        &--spinner {
            height: 100%;
            width: 100%;    
            border: 0.2rem solid #f3f3f3;
            border-top: 0.2rem solid #1f1d1da1;
        }
    }

    &-tooltip {
        position: absolute;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        background-color: #000000;
        color: $white;
        white-space: nowrap;
        font-size: 0.8rem;
        font-weight: 700;
        line-height: 1;
        padding: 10px 15px;
        border-radius: 6px;
        visibility: hidden;

        &:after {
            content: " ";
            position: absolute;
            top: 100%;
            /* At the bottom of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }

        &.solid {
            background-color: $blue;

            &:after {
                border-color: $blue transparent transparent transparent;
            }
        }
    }

    &:hover {
        #{ $self }-tooltip {
            visibility: visible;
        }
    }
}