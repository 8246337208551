.page-clinical-articles{
    &--list{
        padding-top: 0;
        margin-top: -20px;
        @include for-desktop-up{
            // display: flex;
            // flex-wrap: wrap;
            // flex-direction: row;
            // .card{
            //     width: calc((100% - 20px)/2);
            //     &:nth-child(2n){
            //         margin-left: 20px;
            //     }
            // }
        }
    }
}