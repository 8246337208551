.footer{
    height: 100px;
    width: 100%;
    &--inner{
        // @include inner-div;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: center;
        position: relative;
        background-color: $black;
    }
    &--menu{
        &-item{
            text-decoration: none;
            color: $grey_subtitle;
            font-size: 1.16rem;
            line-height: 1;
            font-weight: 500;
            padding: 0 10px;
            &:hover{
                @include for-tablet-portrait-up{
                    color: $white;
                }
            }
        }
    }
    &--version{
        position: absolute;
        right: $padding_default_d;
        top: 50%;
        color: $white;
        margin-left: auto;
        transform: translateY(-50%);
        font-size: 0.8rem;
        font-weight: 700;
    }
}