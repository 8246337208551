.loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    &.full{
      height: 100%;
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
      //background: rgba(255,255,255, 0.8);
    }
  
    &--spinner{
      border: 0.5rem solid #f3f3f3; 
      border-top: 0.5rem solid #1f1d1da1; 
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      animation: spin 2s linear infinite;
    }
    &--preload-bar{
        position:fixed;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 4px;
        background-color: $blue;
        z-index:10;
        animation: bar 2s infinite;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes bar {
    from {width: 0%;}
    to {width: 100%;}
  }