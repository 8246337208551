.page-ct-single{
    &--page-content{
        .page-content--inner{
            @include for-desktop-up{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    &--aside{
        @include for-desktop-up{
            width: 40%;
            padding-left: 30px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    &--content{
        @include for-desktop-up{
            flex: 0 0 auto;
            width: 60%;
            padding-right: 30px;
        }
        &-item{
            b{
                color: $blue;
            }
            p{
                font-weight: 500;
            }
        }
        > .page-ct-single--content-item{
            margin-bottom: 10px;
        }
        > .page-ct-single--content-item ~ .page-ct-single--content-item{
            margin-bottom: 30px;
        }
        h2{
            margin-bottom: 20px;
        }
    }
    &--title{
        padding-bottom: 25px;
    }
    &--accordions{
        .accordion--content {
            &:not(.collapsed){
                padding-top: 35px;
                padding-bottom: 35px;
            }
        }
    }
    &--info{
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid $grey;
        display: flex;
        flex-direction: column;
        position: relative;
        &-header{
            background-color: $grey-light;
            padding: 15px;
            display: flex;
            width: 100%;
            border-bottom: 1px solid $grey;
            @include for-phone-up{
                padding: 18px 25px;
            }
            &-status{
                color: $black;
                font-weight: 700;
                position: relative;
                padding-left: 25px;
                &::before{
                    content: '';
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $grey;
                    border-radius: 50%;
                }
                &-0 {
                    &::before{
                        background: #E84D59;
                    }
                }
                &-1 {
                    &::before{
                        background: #FFCC5A;
                    }
                }
                &-2 {
                    &::before{
                        background: #3AAA8A;
                    }
                }
                &-3 {
                    &::before{
                        background: #3AAA8A;
                    }
                }
            }
        }
        &-details{
            padding: 15px;
            @include for-phone-up{
                display: flex;
                flex-wrap: wrap;
                padding: 18px 25px;
            }
            &__item{
                @include for-phone-up{
                    width: 50%;
                }
                &:not(:last-child){
                    margin-bottom: 10px;
                }
                b{
                    color: $blue;
                }
                p{
                    font-weight: 500;
                }
                &:nth-child(1), &:nth-child(3){
                    @include for-phone-up{
                        padding-right: 10px;
                    }
                }
                &:nth-child(6), &:nth-child(7){
                    @include for-phone-up{
                        width: 100%;
                    }
                }
                &_tags{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: -5px;
                    margin-top: 5px;
                    .text--tag{
                        margin-bottom: 5px;
                    }
                    .text--author{
                        margin-bottom: 5px;
                        &:not(:last-child){
                            margin-right: 10px;
                        }
                        svg{
                            fill: $dark_40;
                            margin-left: 5px;
                            height: 9px;
                        }
                    }
                }
            }
            .btn-primary{
                margin-top: 20px;
            }
        }
    }
}