.page-event-register-success{
    &--details{
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            font-size: 1.88rem;
            margin-bottom: 10px;
        }
        div{
            margin-top: 5px;
            font-weight: 500;
        }
    }
    &--actions{
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn{
            width: 100%;
            @include for-desktop-up{
                width: 60%;
            }
            &:not(:first-of-type){
                margin-top: 5px;
            }
            svg{
                width: 30px;
            }
        }
        .btn-outline{
            &:not(:first-of-type){
                margin-top: 25px;
            }
        }
    }
    &--password{

        margin-top: 50px;
        background-color: $grey_light;
       
        &-content{
            padding: 20px 55px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h2{
                text-align: center;
                max-width: 600px;
            }
        }
        .set-new-password-form{
            padding-top: 40px;
            max-width: 400px;
            width: 100%;
            .form-label{
                color: $purple;
            }
        }
    }
    
}
