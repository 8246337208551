form {
  button{
    padding: 0;
    margin-top: 25px;
    border: none;
    background: none;
  }
}

.form-label{
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5px;
  color: $blue;
  display: flex;
  span{
    margin-left: auto;
    color: $black;
    font-size: 14px;
  }
}
.form-description{
  font-size: 12px;
  font-weight: 600;
  a{
    color: $black;
    &:hover{
      color: $blue;
    }
  }
}

.form-group {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  &.row{
    flex-direction: row;
    align-items: center;
  }
  input {
    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="number"],
    &:not([type]){
      border: 1px solid $grey;
      width: 100%;
      padding: 0 10px;
      height: 59px;
      border-radius: 6px;
      font-size: 16px; // prevent zoom on mobile
      &:focus {
        outline: none;
        //background: none;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus, 
      &:-webkit-autofill:active
      {
        box-shadow: 0 0 0 30px white inset !important;
      }
      &::placeholder {
        color: $dark_40;
      }
      &.has-icon{
        background-repeat: no-repeat;
        background-position: 12px center;
        background-size: auto 40%;
        padding-left: 40px;
      }
    }
  }
  .form-textarea{
      border: 1px solid $grey;
      width: 100%;
      padding: 10px;
      border-radius: 6px;
      font-size: 16px;
      resize: none;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $dark_40;
      }
  }
}
.form-container{
  background-color: $grey_light;
  border: 1px solid $grey;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
  &--close{
    position: absolute;
    right: 5px;
    top: 5px;
    height: 35px;
    width: 35px;
    padding: 5px;
    cursor: pointer;
    svg{
      fill: $blue;
      width: 100%;
      height: auto;
    }
    &:active{
      opacity: 0.7;
    }
  }
  &.has-close{
    padding-top: 35px;
  }
  .form-group{
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  & + .add-btn{
    margin-bottom: 25px;
  }
}
.form-actions{
  display: flex;
  flex-direction: row-reverse;
  &.sticky{
    background-color: $grey_light;
    padding: 20px;
    border-radius: 15px;
    position: sticky;
    bottom: 15px;
    @include for-phone-only{
      bottom: 0;
      border-radius: 0;
      margin-left: -$padding_default_m;
      margin-right: -$padding_default_m;
    }
  }
  
  .btn{
    margin: 0;
    width: 48%;
    flex: 1;
    &:not(:first-of-type){
      margin-right: 4%;
    }
  }
}

//react select
.form-select-container{
  font-size: 16px; //avoid zoom on mobile
  .form-select{
    &__control{
      border-color: $grey;
      border-radius: 6px;
      &--is-focused{
        border-color: $blue;
        box-shadow: 0 0 0 1px $blue;
      }
    }
    &__indicator-separator{
      background-color: $grey;
    }
    &__menu{
      z-index: 4;
    }
    &__value-container{
      flex-wrap: nowrap;
    }
    &__input{
      input{
        height: 45px;
      }
    }
    &__placeholder{
      color: $dark_40;
    }
  }
}



/* Customize the label (the container) */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  &:hover{
    input ~ .checkmark {
      background-color: #ccc;
    }
  }
  input{
    position: absolute;
    opacity: 0;
    left: 0;
    cursor: pointer;
    // height: 0;
    // width: 0;
    &:checked{
      ~ .checkmark {
        background-color: $blue;
        border-color: $blue;
        &:after {
          display: block;
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    border: 2px solid $grey_subtitle;
    border-radius: 3px;
    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 0px;
      width: 7px;
      height: 13px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

.form-errors{
  background-color: rgba($color: $red_error, $alpha: 0.2);
  border: 1px solid rgba($color: $red_error, $alpha: 0.7);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  &--item{
    font-weight: 600;
    color: $red_error;
    padding-left: 13px;
		position: relative;
    &:not(:first-of-type){
      margin-top: 5px;
    }
    &::before{
			content: "";
			width: 6px;
			height: 6px;
			background: $red_error;
			border-radius: 50%;
			position: absolute;
			top: 7px;
			left: 0;
		}
  }
}
