.page-search{
    &--page-head{
        margin-bottom: 0;
        @include for-tablet-only{
            padding-top: 0;
        }
    }
    &--form{
        background-color: $blue;
        padding-left: $padding_default_m;
        padding-right: $padding_default_m;
        padding-bottom: 25px;
        position: relative;
        form{
            width: 100%;
            @include for-tablet-portrait-up{
                display: flex;
            }
        }
        @include for-tablet-portrait-up{
            background-color: transparent;
            
            &.flush{
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .form-group{
            margin-bottom: 0;
            @include for-tablet-portrait-up{
                padding-right: 10px;
                flex: 1;
            }
            input[type='text']{
                border-color: $white;
                @include for-tablet-portrait-up{
                    background-color: $grey_light;
                    border-color: $grey_light;
                    height: 50px;
                    border-radius: 10px;
                }
                @include for-tablet-only{
                    padding-right: 50px ;
                }
            }
        }
        .btn{
            margin-top: 6px;
            @include for-tablet-only{
                position: absolute;
                top: 0;
                right: $padding_default_m;
                height: 45px;
                border: none;
                border-radius: 6px;
                span{
                    display: none;
                }
                background-color: transparent;
                padding-right: 15px;
                padding-left: 15px;
                svg{
                    fill: $blue;
                    width: 20px;
                }
            }
        }
    }
    h3{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $grey;
        padding-bottom: 25px;
        padding-left: $padding_default_m;
        padding-right: $padding_default_m;
        .text-link{
            text-decoration: none;
        }
        @include for-tablet-portrait-up{
            padding-left: 0;
            padding-right: 0;
        }
    }
    &--item{
        display: block;
        font-size: 1.35rem;
        font-weight: 600;
        border-bottom: 1px solid $grey;
        padding-bottom: 15px;
        padding-top: 50px;
        padding-left: $padding_default_m;
        padding-right: $padding_default_m;
        text-decoration: none;
        color: $black;
        cursor: pointer;
        position: relative;
        span{
            font-weight: 400;
            font-size: 1rem;
        }
        @include for-tablet-portrait-up{
            padding-left: 0;
            padding-right: 0;
        }
        .item-excerpt{
            font-weight: 400;
            font-size: 1.15rem;
        }
        .card-standard--stamp{
            left: 0;
        }
    }
    &--results{
        h3{
            .btn{
                font-size: 1rem;
                color: $black;
                span{
                    margin-left: 5px;
                    margin-right: 0;
                    color: $black;
                }
                svg{
                    width: 11px;
                    fill: $black;
                }
                &:hover{
                    color: $blue;
                    span{
                        color: $blue;
                    }
                    svg{
                        fill: $blue;
                    }
                }
            }
        }
        @include for-tablet-portrait-up{
            &.flush{
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &--recent{
        @include for-tablet-portrait-up{
            &.flush{
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &--suggested{
        &.section{
            padding-top: 50px;
        }
    }
    &--carousel{
        .carousel--content{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include for-desktop-up{
                .card{
                    width: calc((100% - 40px)/3);
                    &:nth-child(2n){
                        margin-left: 0;
                    }
                    &:nth-child(3n -1){
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

.page-search--recent{
    padding-top: 50px;
    .page-search--item{
        display: flex;
        justify-content: space-between;
        padding-top: 15px; 
    }
}