.card-subtopic{
    overflow: hidden;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid $grey_border;
    padding: 19px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: $black;
    &.is-disabled{
        opacity: 0.6;
        cursor: default;
    }
    &--figure{
        width: 25%;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 14px;
        background-color: $black;
        position: relative;
        color: $white;
        &::after{
            padding-bottom: 100%;
        }
        &-index{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.833rem;
            line-height: 1;
            font-weight: 700;
        }
    }
    &--content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 10px;
        width: calc(75% - 14px - 14px);
        .progress-bar{
            width: 80%;
            margin-top: 5px;
        }
    }
    &--title{
        margin-bottom: 5px;
    }
    &--icon{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 14px;
        svg{
            fill: $black;
            width: 100%;
            height: auto;
        }
    }
}