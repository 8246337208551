.split-content {
  @include for-desktop-up {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    > :first-child {
      width: 50%;
    }
    > :last-child {
      width: 50%;
    }
    &.w-60-40 {
      > :first-child {
        width: 60%;
      }
      > :last-child {
        width: 40%;
      }
    }
    &.w-40-60 {
      > :first-child {
        width: 40%;
      }
      > :last-child {
        width: 60%;
      }
    }
  }
}
