.page-head{
    margin-bottom: 30px;
    padding-top: 35px;
    @include for-tablet-portrait-up{
        padding-top: 0;
    }
    &.blue{
        @include for-tablet-only{
            background-color: $blue;
            padding-top: 15px;
            padding-bottom: 30px;
        }
    }
    &--inner{
        @include inner-div;
        @include for-tablet-portrait-up{
            padding: 0;  
        }
    }
    &--title{
        display: flex;
        align-items: center;
        .btn{
            margin-left: auto;
            font-size: 1.333rem;
            &.filter{
                svg{
                    &:first-child{
                        fill: $green;
                        .blue &{
                            fill: $white;
                        }
                    }
                }
            }
        }
        .blue &{
            @include for-tablet-only{
                color: $white;
            }
        }
    }
    &--breadcrumb{
        font-weight: 500;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .text{
            margin-bottom: 5px;
        }
    }
    &--subtitle{
        padding-top: 10px;
        max-width: 600px;
        .blue &{
            @include for-tablet-only{
                color: $white;
            }
        }
    }
    &--back-btn{
        color: $black;
        margin-bottom: 20px;
        svg{
            height: 14px;
            width: auto;
        }
    }
}