.page-profile{
    .user-summary{
        @include for-desktop-up{
            position: sticky;
            top: 15px
        }
    }
    &--details{
        @include for-desktop-up{
            padding-left: 40px;
            padding-top: 0;
        }
        &-professional{
            padding-top: 35px;
        }
        &-practice{
            padding-top: 35px;
        }
        &-item{
            padding: 15px 0;
            border-bottom: 1px solid $grey_border;
            &:first-of-type{
                margin-top: 10px;
            }
            @include for-tablet-only{
                margin-left: -$padding_default_m;
                margin-right: -$padding_default_m;
                padding-left: $padding_default_m;
                padding-right: $padding_default_m;
            }
            &__label{
                font-weight: 600;
                color: $blue;
                margin-bottom: 5px;
            }
            &__value{
                font-size: 1.166rem;
                font-weight: 700;
            }
        }
    }  
}