.horizontal-scoller {
  display: flex;
  &--content{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    user-select: none;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    @include for-desktop-up{
      width: calc(100% - 50px);
    }
    
  }
  &--icon{
    width: 30px;
    height: auto;
    fill: $blue;
    margin-left: 20px;
    
    @include for-tablet-landscape-only{
      display: none;
    }
  }
}
