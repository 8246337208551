.section{
    padding-top: 35px;
    &:first-of-type{
        padding-top: 0;
        //padding-bottom: 0;
    }
    &:last-of-type{
        padding-bottom: 35px
    }
    &.pt-0{
        padding-top: 0
    }
    &.pb-0{
        padding-bottom: 0
    }
    &--inner{
        @include inner_div;
        
    }
    &.flush{
        margin-left: -$padding_default_m;
        margin-right: -$padding_default_m;
        @include for-tablet-portrait-up{
            margin-left: -$padding_default_d;
            margin-right: -$padding_default_d;
        }
    }
}