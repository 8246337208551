.page-single-article {
    &--content {
        height: 100%;

        .page-content--inner {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    &--page-head {
        &.page-head {
            margin-bottom: 0;

            @include for-tablet-only {
                padding-bottom: 0;
                margin-bottom: 0;
                padding-top: 0;
            }
        }
    }

    &--hero {
        @include for-tablet-only {
            margin-left: -$padding_default_m;
            margin-right: -$padding_default_m;
        }
    }

    &--head {
        .article-media & {
            @include for-tablet-only {
                background-color: $grey_light;
            }
        }

        .article-text & {
            @include for-tablet-only {
                border-radius: 35px;
                background-color: $white;
                margin-top: -30px;
                z-index: 1;
                position: relative;
            }

            &.has-border {
                @include for-tablet-only {
                    border: none;
                }

            }
        }

        .text--category {
            display: inline-flex;
            margin-bottom: 10px;
        }

        border-bottom: 1px solid $grey_border;
        padding-bottom: 35px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include for-tablet-landscape-up {
            flex-direction: row;
            align-items: center;
        }

        &-image {
            @include for-tablet-landscape-up {
                width: 40%;
                flex: 0 0 auto;
            }
        }
    }

    &--time {
        padding-bottom: 5px;
    }

    &--subtitle {
        font-size: 1.33rem;
        line-height: 1.6rem;
        font-weight: 700;
        padding-bottom: 8px;

        .article-text & {
            color: $grey_subtitle;
        }
    }

    &--tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        .text--category {
            margin-bottom: 5px;
        }
    }

    &--d-tags {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;
        margin-top: 15px;
    }

    &--section {
        margin-left: -$padding_default_m;
        margin-right: -$padding_default_m;
        padding-left: $padding_default_m;
        padding-right: $padding_default_m;
        padding-bottom: 35px;

        @include for-tablet-portrait-up {
            padding-left: 50px;
            padding-right: 50px;
            margin-left: 0;
            margin-right: 0;
        }

        &.has-border {
            border-bottom: 1px solid $dark_30;

            @include for-tablet-portrait-up {
                border-bottom: 1px solid $grey_border;
            }
        }

        h3 {
            padding-bottom: 10px;
        }
    }

    &--grid {
        display: flex;
        flex-direction: column;

        @include for-desktop-up {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .card {
            @include for-desktop-up {
                width: calc((100% - 20px)/2);

                &:nth-child(2n) {
                    margin-left: 20px;
                }
            }
        }
    }

    &--the-content {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 20px;
        }

        img {
            width: auto;
            max-width: 100%;
            display: inline;
        }

        p>img.alignleft {
            float: left;
            margin-right: 15px;
        }

        p>img.alignright {
            float: right;
            margin-left: 15px;
        }

        .scrollable-img {
            @include for-tablet-landscape-only {
                overflow-x: auto;

                img {
                    max-width: 1000px;
                }
            }
        }

        .btn,
        .btn-primary {
            width: fit-content !important;
            margin: 0;
        }

        &.is-locked {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100px;
                /* Adjust to control the gradient height */
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }
    }

    &--actions {
        padding-left: $padding_default_m;
        padding-right: $padding_default_m;
        margin-left: -$padding_default_m;
        margin-right: -$padding_default_m;
        background-color: $grey_light;
        position: sticky;
        bottom: 0;
        margin-bottom: -20px;
        margin-top: auto;
        border-top: 1px solid #EAEBEC;

        @include for-tablet-only {
            bottom: 80px;
        }

        @include for-tablet-landscape-up {
            padding-left: $padding_default_d;
            padding-right: $padding_default_d;
            margin-left: -$padding_default_d;
            margin-right: -$padding_default_d;
            bottom: 0;
            margin-bottom: -45px;
        }

        &-content {
            @include for-tablet-portrait-up {
                padding-left: 50px;
                padding-right: 50px;
            }

            width: 100%;
        }

        .btn {
            justify-content: center;
            width: 100%;

            @include for-desktop-up {
                width: 50%;
            }

        }
    }

    table {
        border-top: 1px solid $grey_subtitle;
        border-left: 1px solid $grey_subtitle;
        margin-bottom: 20px;

        td {
            padding: 5px 10px;
            border-right: 1px solid $grey_subtitle;
            border-bottom: 1px solid $grey_subtitle;
        }
    }
}