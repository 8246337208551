.page-favourites{
    &--list{
        &-grid{
            display: flex;
            flex-wrap: wrap;
            @include for-tablet-landscape-only{
                justify-content: center;
            }
            .card-doctor-circular{
                margin: 25px
            }
        }
    }
    &--form{
        @include for-desktop-up{
            display: flex;
        }
        .form-group{
            @include for-desktop-up{
                margin-bottom: 0;
                margin-right: 20px;
                flex: 1;
            }
        }
        .form-label{
            color: $black;
            font-size: 1.625rem;
            line-height: 2rem;
            margin-bottom: 10px;
        }
        .form-select-container {
            .form-select{
                &__value-container{
                    flex-wrap: wrap;
                }
            }
        }
        .btn-primary{
            @include for-desktop-up{
                width: 295px;
                align-self: flex-end;
                height: 59px;
            }
        }
    }
}