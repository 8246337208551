.card-feed{
    overflow: hidden;
    border-radius: 10px;
    background-color: $grey_light;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    &--main{
        background-color: $white;
        border-radius: 10px;
        border: 1px solid $grey_border;
        padding: 19px 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-decoration: none;
        color: $black;
        height: 100%;
        cursor: pointer;
        &:hover{
            border-color: $grey_subtitle;
        }
    }
    &--content{
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: flex-start;
        padding-bottom: 15px;
        width: 100%;
        margin-bottom: auto;
        &-figure{
            width: 25%;
            border-radius: 12px;
            overflow: hidden;
            margin-right: 14px;
            &::after{
                padding-bottom: 100%;
            }
        }
        &-data{
            flex: 1
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    &--categories{
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
        .progress-bar{
            &:not(:first-child){
                margin-left: 5px;
            }
            &--line{
                width: 90px;
            }
            
        }
    }
    &--date-completed{
        margin-left: 10px;
    }
    &--title{
        margin-bottom: 10px;
    }
    &--authors{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        .text--author{
            margin-bottom: 5px;
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    &--tags{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &--time{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &--ctas{
        padding: 20px;
        .btn{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}