.pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    &--item{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        color: $blue;
        border: 1px solid $blue;
        font-size: 1.16rem;
        font-weight: 700;
        text-decoration: none;
        margin-right: 5px;
        &:last-child{
            margin-right: 0;
        }
        &.active{
            background-color: $blue;
            color: $white;
        }
        &:hover{
            @include for-tablet-portrait-up{
                background-color: $blue;
                color: $white;
            }
        }
    }
}