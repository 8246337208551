a, .text-link{
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    &.white{
        color: $white;
    }
    &:active{
        opacity: 0.7;
    }
    &.menu-item{
        display: flex;
        align-items: center;
        font-size: 1.333rem;
        line-height: 2rem;
        color: $black;
        font-weight: 700;
        text-decoration: none;
        border-bottom: 1px solid $grey_border;
        padding: 20px 0;
        &:first-of-type{
            padding-top: 0;
        }
        svg{
            fill: $blue;
            &.icon-title{
                margin-right: 22px;
                width: 20px;
                height: auto;
            }
            &.icon-next{
                margin-left: auto;
                width: 14px;
                height: auto;
            }
        }
    }
}