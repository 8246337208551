.page-refer-patient{
    &--page-content{
        @include for-tablet-portrait-up{
            margin-top: -20px;
        }
    }
    &--ctas{
        @include for-tablet-landscape-up{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .card{
                width: calc((100% - 15px) / 2);
                &:nth-child(2n){
                    margin-left: 15px;
                }
            }
        }
    }
    .success--message{
        .btn{
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
}
