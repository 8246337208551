.taosts{
    &--container{
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        width: 96%;
        max-width: 480px;
        @include for-tablet-portrait-up{
            transform: translateX(0);
            left: inherit;
            right: 20px;
        }
    }
}