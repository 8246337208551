.card-clinical-trial{
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid $grey;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    &:hover{
        border-color: $grey_subtitle;
    }
    &--content{
        display: flex;
        flex-direction: column;
        padding: 33px 25px;
        flex: 1;
        > .card-clinical-trial--tags{
            margin-top: 20px;
        }
        > .card-clinical-trial--tags ~ .card-clinical-trial--tags{
            margin-top: 0;
        }
    }
    &--title{
        margin-bottom: 10px;
        font-size: 1.333rem;
        line-height: 1.25;
    }
    &--summary{
        font-weight: 500;
        margin-bottom: auto;
    }
    &--tags{
        margin-bottom: 10px;
        &-title{
            font-weight: 700;
            margin-bottom: 10px;
        }
        &-items{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: -5px;
            .text--tag{
                margin-bottom: 5px;
            }
        }
    }
    &--footer{
        background-color: $grey-light;
        padding: 18px 25px;
        display: flex;
        width: 100%;
        border-top: 1px solid $grey;
        &-status{
            color: $black;
            font-weight: 700;
            position: relative;
            padding-left: 25px;
            &::before{
                content: '';
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: $grey;
                border-radius: 50%;
            }
            &-0 {
                &::before{
                    background: #E84D59;
                }
            }
            &-1 {
                &::before{
                    background: #FFCC5A;
                }
            }
            &-2 {
                &::before{
                    background: #3AAA8A;
                }
            }
            &-3 {
                &::before{
                    background: #3AAA8A;
                }
            }
        }
    }
}