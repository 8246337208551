.page-find-doctor{
    &--page-content{
        @include for-tablet-portrait-up{
            margin-top: -20px;
        }
    }
    &--ops{
        padding-top: 30px;
        padding-bottom: 50px;
        @include for-desktop-up{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        @include for-tablet-only{
            padding-top: 0;
        }
    }
    &--ctas{
        @include for-tablet-landscape-up{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .card{
                width: calc((100% - 15px) / 2);
                &:nth-child(2n){
                    margin-left: 15px;
                }
            }
        }
    }
    &--favourites{
        @include for-desktop-up{
            width: calc((100% - 15px) / 2);
            margin-right: 15px;
            margin-bottom: 0;
        }
        margin-bottom: 50px;
        .horizontal-scoller{
            width: 100%;
            margin-top: 20px;
            align-items: center;
            &--content{
                max-width: 900px;
                .card-doctor-circular{
                    flex: 0 0 auto;
                    margin-right: 50px;
                    margin-left: 0;
                    margin-top: 0;
                    width: 90px;
                    &--title{
                        font-size: 1rem;
                        line-height: 1.2;
                    }
                }
            }
            &--icon{
                height: 28px;
                width: auto;
            }
        }
    }
    &--quick-search{
        display: flex;
        flex-direction: column;
        h2{
            margin-bottom: 20px;
        }
        .form-group{
            margin-bottom: 0;
            justify-content: center;
            height: 100%;
        }
        @include for-desktop-up{
            width: calc((100% - 15px) / 2);
        }
    }
}
