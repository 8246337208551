/* Colours */
$white: #ffffff;
$black: #333C43;
$grey_dark: #2A353F;
$grey: #CDCFD1;
$grey_subtitle: #979797;
$grey_light: #F4F4F4;
$blue: #006FBA;
$blue_dark: #1D548A;
$blue_info: #4697CE;
$yellow: #FFC700;
$yellow_warning: #C9A036; 
$dark_70: #000000;
$dark_40: #5F686F;
$dark_30: #949A9F;
$green: #009572;
$purple: #7c439a;
$red_error: #B64709;
$red:#E0444D;

$blue_light: #C2DFFF;
$purple-dark: #21096c;
$grey_border: #DADADA;

/* Dimensions */

$content_width_max: 1800px;
$header_height: 85px;
$header_height_desktop: 100px;
$bottom_menu_height: 80px;

/* Fonts */
$base-font: 'inter', sans-serif;

/* Global Padding */
$padding_default_m: 20px;
$padding_default_d: 75px
/* Easing */
// $ease-out: cubic-bezier(0.190, 1.000, 0.220, 1.000);