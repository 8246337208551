/* Header */
.welcome-header{
    .header--buttons{
        @media screen and (max-width: 560px) {
            gap: 10px;
        }
        .btn{
            @media screen and (max-width: 560px) {
                height: 40px;
                padding: 0px 12px;
            }
        }
    }
}

/* Intro */
.welcome-intro{
    position: relative;
    background-color: $blue-dark;
    color: $white;
    text-align: center;
    padding: 20px 15px 100px 15px;
    min-height: 300px;
    height: 60vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @media screen and (min-width: 960px) {
        padding: 20px 15px 200px 15px;
    }
    h1{
        font-size: clamp(30px, 8vw, 68px);
        line-height: 1;
        font-weight: 700;
        margin: 0px auto 0.5em auto;
        max-width: 780px;
        position: relative;
        z-index: 2;
    }
    p{
        font-size: clamp(18px, 3vw, 24px);
        line-height: 1.25;
        font-weight: 500;
        margin: 0px auto;
        max-width: 780px;
        position: relative;
        z-index: 2;
    }
}

.welcome-bg-1{
    background-image: url('/../public/welcome_intro_bg_1.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 85% 85%;
    width: 895px;
    height: 860px;
    position: absolute;
    right: 70%;
    bottom: -10%;
    pointer-events: none;
    z-index: 1;
}   

.welcome-bg-2{
    background-image: url('/../public/welcome_intro_bg_2.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 85% 85%;
    width: 692px;
    height: 674px;
    position: absolute;
    left: 75%;
    top: 10%;
    pointer-events: none;
    z-index: 1;
}

/* Modules */

.welcome-modules{
    width: 100%;
    display: block;
    background-color: $white;
    --max-content-width: 1300px;
    --section-space: 50px;
    @media screen and (min-width: 960px) {
        --section-space: 150px;
    }
    h2{
        color: #404C59;
        font-size: clamp(1.75rem, 5vw, 3rem);
        margin-bottom: 1em;
        line-height: 1em;
    }
    p{
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 1em;
    }
}