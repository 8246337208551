.carousel{
    &--dots{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        &-item{
            background-color: rgba($color: $dark_30, $alpha: 0.6);
            border: none;
            width: 10px;
            height: 10px;
            margin-right: 2px;
            padding: 0;
            border-radius: 50%;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                background-color: $blue;
            }
        }
    }
}