html, body, #root{
	height: 100%;
}

// html{
// 	scroll-behavior: smooth;
// }

body{
	background-color: $white;
	//overflow: hidden;
	&::before{
		content: "xsmall";
		display: none;
		visibility: hidden;
		
		@include for-phone-up{
			content: "for-phone-up";
		}
		@include for-tablet-portrait-up{
			content: "for-tablet-portrait-up";
		}
		@include for-tablet-landscape-up{
			content: "for-tablet-landscape-up";
		}
		@include for-desktop-up{
			content: "for-desktop-up";
		}
		@include for-big-desktop-up{
			content: "for-big-desktop-up";
		}
		@include for-extra-big-desktop-up{
			content: "for-extra-big-desktop-up";
		}
	}
}

#root{
	position: relative;
	//overflow: auto;
}
p{
	&:not(:last-child){
		padding-bottom: 10px;
	}
	&:first-child{
		padding-bottom: 10px;
	}
}

ul:not([class]){
	color: inherit;
	margin-bottom: 1.25em;
	padding-left: 0;
	li{
		color: inherit;
		padding-left: 16px;
		position: relative;
		list-style: none;
		&::before{
			content: "";
			width: 6px;
			height: 6px;
			background: $blue;
			border-radius: 50%;
			position: absolute;
			top: 6px;
			left: 0;
		}
		ul{
			li{
				&::before{
					content: "";
					border: 1px solid $blue;
					background: none;
				}
			}
		}
		a:not([class]), a[class^="AVANSER"], a[class*=" AVANSER"]{
			color: $blue;
			border-bottom: 1px solid $blue;
			&:hover{
				.desktop &{
					color: $yellow;
					border-bottom-color: $yellow;
				}
			}
		}
		strong{
			a:not([class]), a[class^="AVANSER"], a[class*=" AVANSER"]{
				font-weight: 700;
				border-bottom-width: 2px;
			}
		}
	}
}
ol:not([class]){
	list-style: none;
	counter-reset: my-counter;
	margin: 0;
	padding: 0;
	margin-top: 20px;
	li{
		counter-increment: my-counter;
		margin-bottom: 20px;
		position: relative;
		padding-left: 22px;
		&:last-child{
			margin-bottom: 0;
		}
		&::before{
			content: counter(my-counter) '.';
			font-weight: 700;
			margin-right: 10px;
			color: $blue;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
img{
	width: 100%;
	height: auto;
}
table{
	width: 100%;
	border-color: $grey_border;
	border-collapse: collapse;
	th{
		border-color: $grey_border;
	}
	td{
		border-color: $grey_border;
	}
}

.preloaded-images {
    visibility: hidden;
    display: none;
    position: absolute;
    top: -100vh;
    left: -100vh;
}