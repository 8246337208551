.qanda{
    &--item{
        margin-bottom: 30px;
        &:first-of-type{
            margin-top: 30px;
        }
        &-question{
            margin-bottom: 10px;
        }
        &-answer{
            border: 1px solid $black;
            border-radius: 10px;
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 30px;
            &:not(.non-clickable){
                cursor: pointer;
                &:active{
                    opacity: 0.7;
                }
                &:hover{
                    @include for-tablet-portrait-up{
                        background: $grey_light;
                        color: $black;
                        border: 1px solid $black;
                    }
                }
            }
            &:last-of-type{
                margin-bottom: 0;
            }
            &.selected{
                background-color: $grey;
                font-weight: 700;
            }
            &.correct{
                background-color: rgba($color: $green, $alpha: 0.2);
                font-weight: 700;
                color: $green;
                border-color: $green;
            }
            &.wrong{
                background-color: rgba($color: $red, $alpha: 0.2);
                font-weight: 700;
                color: $red;
                border-color: $red;
            }

        }
    }
}