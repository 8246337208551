.page-doctor-closest, .page-doctor-viewed{
    &--content{
        &-list{
            .card-doctor{
                &:first-of-type{
                    @include for-phone-only{
                        margin-top: 0;
                    }
                    
                }
            }
            @include for-phone-up{
                display: flex;
                flex-wrap: wrap;
                margin-top: -20px;
                .card-doctor{
                    width: calc((100% - 20px)/2);
                    &:nth-child(2n){
                        margin-left: 20px;
                    }
                }
            }
            @include for-desktop-up{
                display: flex;
                flex-wrap: wrap;
                .card-doctor{
                    width: calc((100% - 40px)/3);
                    &:nth-child(2n){
                        margin-left: 0;
                    }
                    &:nth-child(3n -1){
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
