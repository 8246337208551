.card-standard{
    $self: &;
    overflow: hidden;
    border-radius: 10px;
    background-color: $grey_light;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    &--content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 18px;
        flex: 1;
    }
    &.pt{
        #{$self}--content{
            padding-top: 60px;
        }
    }
    &--figure{
        &.small{
            &::after{
                padding-bottom: 33%;
            }
        }
    }
    &--categories{
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
    }
    &--description{
        color: $dark_70;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &--title{
        margin-bottom: auto;
        padding-bottom: 10px;
    }
    &--author{
        margin-bottom: 10px;
    }
    &--authors{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        .text--author{
            margin-bottom: 5px;
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    &--tags{
        display: flex;
        flex-wrap: wrap;
    }
    &--stamp{
        position: absolute;
        top: 15px;
        left: 18px;
        &.red{
            background-color: $red;
        }
    }
    &.is-featured{
        @include for-phone-up{
            flex-direction: row-reverse;
            #{$self}--figure{
                width: 50%;
                @include for-desktop-up{
                    width: 40%;
                }
            }
            #{$self}--title{
                margin-bottom: 0;
                @include for-desktop-up{
                    font-size: 2rem;
                    line-height: 2.7rem;
                }
            }
            #{$self}--content{
                justify-content: center;
                width: 50%;
                @include for-tablet-landscape-up{
                    padding: 40px;
                }
                @include for-desktop-up{
                    width: 60%;
                }
            }
        }
    }
}