.card-cta-lm-resource{
    $self : &;
    overflow: hidden;
    border-radius: 10px;
    background-color: $grey_light;
    position: relative;
    padding: 20px 25px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: $black;
    &.is-disabled{
        opacity: 0.6;
        #{$self}--main{
            cursor: default;
        }
    }
    &--main{
        background-color: $grey_light;
        padding: 20px 25px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        height: 100%;
        text-decoration: none;
        color: $black;
    }
    &--figure{
        width: 25%;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 14px;
        &::after{
            padding-bottom: 100%;
        }
        &-svg{
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: darken($grey_light, 3);
            svg{
                width: 25%;
                height: auto;
                fill: $green;
            }
            &.big{
                svg{
                    width: 70%;
                }
            }
        }
    }
    &--content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        padding-right: 14px;
    }
    &--icon{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 40px;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        svg{
            fill: $blue;
            width: 14px;
            height: auto;
            &.green{
                fill: $green;
            }
            &.big{
                width: 28px;
                margin-right: 12px;
            }
        }
        &.blue{
            background-color: $blue;
            svg{
                fill: $yellow;
            }
        }
        &.yellow{
            background-color: $yellow;
            svg{
                fill: $black;
            }
        }
        &.green{
            background-color: $green;
            svg{
                fill: $yellow;
            }
        }
    }
}