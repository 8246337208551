.accordion {
  &--item {
      margin-bottom: 6px;
      &:last-of-type{
          margin-bottom: 0;
      }
  }
  &--title {
      cursor: pointer;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $grey_light;
      font-size: 1.33rem;
      font-weight: 700;
      border-radius: 8px;
      transition: all .05s ease-in-out 0.2s;
      .icon{
          position: relative;
          width: 14px;
          height: 14px;
          &::before{
              content: "";
              width: 100%;
              height: 2px;
              background-color: $black;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              transition: all .05s ease-in-out 0.2s;
          }
          &::after{
            content: "";
            width: 100%;
            height: 2px;
            background-color: $black;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            transition: all .05s ease-in-out 0.2s;
        }
      }
      &.open{
        background-color: $blue;
        color: $white;
        .icon{
            &::before{
                background-color: $yellow;
            }
            &::after{
              transform: rotate(0deg);
              background-color: $yellow;
          }
        }
    }
  }
  &--content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    height: auto;
    max-height: 99999px;
    padding-top: 12px;
    padding-bottom: 12px;
    &.collapsed {
      max-height: 0;
      padding-bottom: 0;
      padding-top: 0;
      transition: max-height 0.25s cubic-bezier(0, 1, 0, 1), padding 0.25s ease;
    }
  }
}
