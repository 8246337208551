.toast{
    margin-top: 10px;
    border-radius: 13px;
    padding: 12px;
    color: $white;
    width: 100%;
    animation: show-toast 0.4s;

    &.success{
        background-color: $green;
    }
    &.error{
        background-color: $red_error;
    }
    &.warning{
        background-color: $yellow_warning;
    }
    &.info{
        background-color: $blue_info;
    }
    &--header{
        display: flex;
        align-items: center;
    }
    &--name{
        display: flex;
        align-items: center;
        margin-right: auto;
        text-transform: uppercase;
        opacity: 0.6;
        font-size: 1.1rem;
        margin-bottom: 10px;
        svg{
            width: 19px;
            fill: $white;
            height: auto;
            margin-right: 7px;
        }
    }
    &--close{
        background-color: rgba($black, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        cursor: pointer;
        &:active{
            opacity: 0.7;
        }
        svg{
            width: 45%;
            height: auto;
            fill: $white;
        }
    }
    &--title{
        font-size: 1.25rem;
        line-height: 1.66rem;
        font-weight: 600;
        margin-bottom: 5px;
        display: flex;
        text-decoration: none;
        color: $white;
        svg{
            width: 18px;
            height: auto;
            fill: $white;
            margin-left: 6px;
        }
    }
    &--message{
        font-weight: 600;
    }
    @keyframes show-toast {
        0% {opacity:0;}
        100% {opacity:1;}
      }
}