.card{
    margin-top: 20px;
    text-decoration: none;
}
@import 'cards/standard';
@import 'cards/featured';
@import 'cards/contact';
@import 'cards/feed';
@import 'cards/cta';
@import 'cards/doctor';
@import 'cards/doctor-circular';
@import 'cards/doctor-links';
@import 'cards/cta-search';
@import 'cards/cta-global';
@import 'cards/cta-subpage';
@import 'cards/subtopic';
@import 'cards/cta-lm-resource';
@import 'cards/download-file';
@import 'cards/clinical-trial';