.page-change-password{
    .user-summary{
        @include for-desktop-up{
            position: sticky;
            top: 15px
        }
    }
    &--form{
        @include for-desktop-up{
            padding-top: 0;
        }
    }
}